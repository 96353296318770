<template>
  <div :class="rootClasses">
    <div class="base-dialog__head">
      <div v-if="$slots['subTitle']" class="base-dialog__sub-title">
        <slot name="subTitle"></slot>
      </div>
      <div class="base-dialog__title">
        <slot name="title"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'HbDialog',
  props: {
    size: {
      type: String as () => 'xs' | 'sm' | 'md' | 'lg',
      default: () => 'md',
    },
    noBackground: { type: Boolean, default: () => false },
  },
  setup(props) {
    const rootClasses = computed(() => ({
      'hb-dialog': true,
      'hb-dialog--no-background': props.noBackground,
      [`hb-dialog--${props.size}`]: true,
    }))

    return {
      rootClasses,
    }
  },
}
</script>

<style lang="scss">
.hb-dialog {
  --hb-dialog-x-padding: 33px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--hb-white);
  box-shadow: 0px 30px 60px #aab8be80;
  border-radius: 16px;
  width: 100%;
  position: relative;

  &--xs {
    max-width: 480px;
  }

  &--sm {
    max-width: 500px;

    .hb-dialog__header,
    .hb-dialog__body,
    .hb-dialog__footer {
      padding-right: 30px !important;
      padding-left: 30px !important;
    }

    .hb-dialog__body {
    }

    @media (min-width: 768px) {
      .hb-dialog__footer {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    .hb-form-group {
      padding-right: 30px;
      padding-left: 30px;
      margin-right: -30px;
      margin-left: -30px;
    }

    .dialog-divider {
      margin-right: -30px;
      margin-left: -30px;
    }
  }

  &--md {
    max-width: 760px;
  }

  &--lg {
    max-width: 940px;
  }

  &--no-background {
    background: transparent;
  }

  @include mobile {
    --hb-dialog-x-padding: 33px;
  }
}
</style>
