<template>
  <inner>
    <div class="categories">
      <div class="categories__sidebar">
        <div class="categories__sidebar-inner">
          <div class="categories__sidebar-title">
            {{ $t(helpCategory?.key) }}
          </div>
          <nuxt-link
            v-for="question in helpCategory.questions"
            :key="question.key"
            :to="`/faq/${helpCategory.id}/${question.slug}`"
            class="categories__sidebar-link"
            :class="{
              'categories__sidebar-link--active':
                helpQuestion.key === question.key,
            }"
          >
            <nuxt-icon name="chevron-right" filled />
            {{ $t(question.key) }}
          </nuxt-link>
        </div>
      </div>
      <div class="categories__content">
        <nuxt-link :to="`/faq/${helpCategory.id}`" class="categories__back">
          <nuxt-icon name="arrow-left" filled />
          {{ $t('label.back') }}
        </nuxt-link>
        <div class="categories__title">{{ $t(helpQuestion.key) }}</div>
        <p class="categories__text" v-html="$t(helpQuestion.answer_key)"></p>
      </div>
    </div>
  </inner>
</template>

<script lang="ts">
import { definePageMeta, useI18n, useSeoMeta } from '#imports'
import Inner from '~/layouts/inner.vue'
import { useHelpTools } from '~/composables'

export default {
  name: '[question]',
  components: {
    Inner,
  },
  setup(props, ctx) {
    const { helpCategory, helpQuestion } = useHelpTools(true, true)
    const { t } = useI18n()

    useSeoMeta({
      title: t(helpQuestion.value.key),
      description: t(helpQuestion.value.answer_key),
    })

    definePageMeta({
      layout: false,
    })

    return {
      helpCategory,
      helpQuestion,
    }
  },
}
</script>

<style lang="scss" src="~/assets/styles/help/_help.scss"></style>
