<template>
  <inner>
    <template #subheader>
      <sub-header :fixed="true">
        <template #left>
          <div class="subheader__title">
            <div>{{ $t('saved_searches.title') }}</div>
            <span class="saved-searches__badge">{{ items.length }}</span>
          </div>
        </template>
        <template #right>
          <location-search
            class="saved-searches__location-search"
            placeholder="Start new search"
            is-small
          />
        </template>
      </sub-header>
    </template>
    <list-table
      v-if="isSearchListPending || items?.length"
      :pending="isSearchListPending"
    >
      <template #head>
        <list-table-col-head>
          {{ $t('saved_searches.name') }}
        </list-table-col-head>
        <list-table-col-head>
          {{ $t('saved_searches.subscription') }}
        </list-table-col-head>
        <list-table-col-head>
          {{ $t('saved_searches.created') }}
        </list-table-col-head>
        <list-table-col-head />
      </template>
      <list-table-row v-for="(item, index) in items" :key="`row_${index}`">
        <list-table-col strong top-left>
          {{ item.name }}
        </list-table-col>
        <list-table-col top-right>
          <hb-switch
            :checked="isActive(item)"
            left
            @checked="() => subscribe(item)"
            @unchecked="() => unsubscribe(item)"
          >
            {{ $t(isActive(item) ? 'label.on' : 'label.off') }}
          </hb-switch>
        </list-table-col>

        <list-table-col bottom-left strong>
          <date-format :date-time="item?.created_at?.date_time" />
        </list-table-col>
        <list-table-col bottom-right>
          <hb-raw-btn
            :loading="isPending && isUnderDelete(item.id)"
            @click="onDelete(item.id)"
          >
            <nuxt-icon name="trash" filled />
          </hb-raw-btn>
        </list-table-col>
      </list-table-row>
    </list-table>
    <empty-placeholder v-else>
      <template #illustration>
        <img src="/images/save-searches-placeholder.svg" alt="" />
      </template>
      <template #title>
        {{ $t('saved_searches.empty_title') }}
      </template>
      <template #text>{{ $t('saved_searches.empty_text') }}</template>
      <template #cta>
        <location-search is-small></location-search>
      </template>
    </empty-placeholder>
  </inner>
</template>

<script lang="ts">
import { computed, ref } from 'vue'
import type { SavedSearch, SavedSearchList } from '@homebourse/api-client'
import { SavedSearchChangeStatusStatusEnum } from '@homebourse/api-client'
import Inner from '~/layouts/inner.vue'
import SubHeader from '~/components/layout/SubHeader.vue'
import ListTable from '~/components/ListTable.vue'
import ListTableRow from '~/components/ListTableRow.vue'
import ListTableCol from '~/components/ListTableCol.vue'
import ListTableColHead from '~/components/ListTableColHead.vue'
import HbSwitch from '~/components/base/form/HbSwitch.vue'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'
import {
  definePageMeta,
  useApiHandler,
  useAsyncData,
  useDM,
  useHead,
  useI18n,
  useToasts,
  useUserPropertyStore,
  useUserStore,
} from '#imports'
import HbRawBtn from '~/components/base/utils/HbRawBtn.vue'
import DateFormat from '~/components/DateFormat.vue'
import LocationSearch from '~/components/form/LocationSearch.vue'
import EmptyPlaceholder from '~/components/EmptyPlaceholder.vue'

export default {
  components: {
    LocationSearch,
    DateFormat,
    HbRawBtn,
    Inner,
    SubHeader,
    ListTable,
    ListTableRow,
    ListTableCol,
    ListTableColHead,
    HbSwitch,
    EmptyPlaceholder,
  },

  setup() {
    const { t } = useI18n()

    definePageMeta({
      layout: false,
      mustAuth: true,
    })

    useHead({
      title: t('profile.saved_searches'),
    })

    const isPending = ref<boolean>(false)

    const userStore = useUserStore()
    const userPropertyStore = useUserPropertyStore()
    const deleteQueue = ref<number[]>([])

    const {
      data: searchList,
      pending: isSearchListPending,
      execute: fetchList,
    } = useAsyncData<SavedSearchList>(() =>
      userPropertyStore.api.savedSearches()
    )
    const isActive = (item: SavedSearch) =>
      item?.status === SavedSearchChangeStatusStatusEnum.Active
    const isUnderDelete = (id: number) => deleteQueue.value.includes(id)

    const onDelete = async (id) => {
      const { execute, errorBody } = useApiHandler(async () => {
        isPending.value = true
        const response = await userPropertyStore.api.savedSearchDelete({ id })
        userStore.user.saved_search_count--
        await fetchList()
        return response
      })
      deleteQueue.value.push(id)
      await execute(null, () => {
        useDM().open(FailureDialog, {
          message:
            errorBody.value.message ??
            t('saved_searches.delete_request_failed'),
        })
      })

      const index = deleteQueue.value.indexOf(id)
      deleteQueue.value.splice(index, 1)

      isPending.value = false
    }

    const onSubChange = async (
      item: SavedSearch,
      status: SavedSearchChangeStatusStatusEnum
    ) => {
      const prevStatus = item.status
      item.status = status

      const { execute, errorBody } = useApiHandler(async () => {
        try {
          isPending.value = true
          const response = await userPropertyStore.api.savedSearchChangeStatus({
            id: item.id,
            savedSearchChangeStatus: { status },
          })
          isPending.value = false

          return response
        } catch (e) {
          item.status = prevStatus
          isPending.value = false
          return null
        }
      })

      await execute(
        () => {
          useToasts().show({
            text: t(`saved_searches.${status}_request_successful`),
          })
        },
        () => {
          useDM().open(FailureDialog, {
            message:
              errorBody.value.message ??
              t(`saved_searches.${status}_request_failed`),
          })
        }
      )
    }

    const items = computed<SavedSearch[]>(() => searchList.value?.data ?? [])

    return {
      searchList,
      items,
      isActive,
      onDelete,
      isUnderDelete,
      subscribe: (item: SavedSearch) =>
        onSubChange(item, SavedSearchChangeStatusStatusEnum.Active),
      unsubscribe: (item: SavedSearch) =>
        onSubChange(item, SavedSearchChangeStatusStatusEnum.Inactive),
      isPending,
      isSearchListPending,
    }
  },
}
</script>

<style lang="scss">
.saved-searches {
  &__badge {
    border: 3px solid var(--hb-gray2);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  &__full-width {
    width: 100%;
  }

  &__location-search {
    width: 370px;

    @include tablet {
      button {
        top: 11px !important;
      }

      .hb-autocomplete .autocomplete-fake-button {
        border: 1px solid var(--hb-gray3);
        border-radius: 10px;
        height: 50px;
      }
    }

    @include mobile-md {
      width: 100%;
    }
  }
}
</style>
