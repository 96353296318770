<template>
  <inner v-if="loaded">
    <template #subheader>
      <sub-header :fixed="true">
        <template #left>
          <div class="listing-custom__header-container">
            <a href="/profile/my-projects" class="listing-custom__header">
              <a href="/profile/my-projects" class="listing-custom__cta-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                >
                  <g
                    id="Group_6622"
                    data-name="Group 6622"
                    transform="translate(-336.76 -1072.091)"
                  >
                    <circle
                      id="Ellipse_111"
                      data-name="Ellipse 111"
                      cx="25"
                      cy="25"
                      r="25"
                      transform="translate(336.76 1072.091)"
                      fill="none"
                    />
                    <g
                      id="Icon_feather-arrow-left"
                      data-name="Icon feather-arrow-left"
                      transform="translate(356.772 1091.534)"
                    >
                      <path
                        id="Path_852"
                        data-name="Path 852"
                        d="M0,0"
                        transform="translate(0 6.978)"
                        fill="none"
                        stroke="#2b2051"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                      <path
                        id="Path_853"
                        data-name="Path 853"
                        d="M6.978,13.956,0,6.978,6.978,0"
                        fill="none"
                        stroke="#2b2051"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                    </g>
                  </g>
                </svg>
              </a>
              <div class="subheader__title listing-custom__label-style">
                <div>{{ project?.name ?? $t("my_listings.label") }}</div>
              </div>
            </a>
          </div>
        </template>
        <template #right>
          <hb-btn-wrap>
            <hb-btn to="/home/upload" theme="primary" size="md">
              <nuxt-icon name="house-add" filled />
              <span>{{ $t("label.add_new_listing") }}</span>
            </hb-btn>
          </hb-btn-wrap>
        </template>
      </sub-header>
    </template>
    <block-loader v-if="pending" :min-height="420" />
    <hb-tabs
      v-else-if="userStore.user?.profile_set && !noResults"
      v-model="activeTabIndex"
      :overflowed="false"
      :initial-active-name="initialActiveTabName"
    >
      <hb-tab-navs :secondary="true">
        <hb-tab-nav :items="counts?.active || 0" :disabled="!counts?.active">
          {{ $t("my_listings.active_tab_title") }}
        </hb-tab-nav>
        <hb-tab-nav
          :items="counts?.inactive || 0"
          :disabled="!counts?.inactive"
        >
          {{ $t("my_listings.inactive_tab_title") }}
        </hb-tab-nav>
        <hb-tab-nav :items="counts?.sold || 0" :disabled="!counts?.sold">
          {{ $t("my_listings.sold_tab_title") }}
        </hb-tab-nav>
        <hb-tab-nav
          :items="counts?.approval || 0"
          :disabled="!counts?.approval"
        >
          {{ $t("my_listings.approval_tab_title") }}
        </hb-tab-nav>
        <hb-tab-nav
          :items="counts?.confirmation || 0"
          :disabled="!counts?.confirmation"
        >
          {{ $t("my_listings.confirmation") }}
        </hb-tab-nav>
        <hb-tab-nav :items="counts?.draft || 0" :disabled="!counts?.draft">
          {{ $t("my_listings.draft_tab_title") }}
        </hb-tab-nav>
      </hb-tab-navs>
      <hb-tabs-body>
        <CommonTab
          :status="MyListingSearchStatusEnum.Active"
          :name="tabNames.active"
          :title="$t('my_listings.my_active_listings')"
          :info="$t('my_listings.my_active_listings_info')"
          :project-slug="project?.slug ?? ''"
          @status-changed="fetchCounts()"
          @updated:items="onUpdatedItems(tabNames.active, $event)"
        />
        <CommonTab
          :status="MyListingSearchStatusEnum.Inactive"
          :name="tabNames.inactive"
          :title="$t('my_listings.my_inactive_listings')"
          :info="$t('my_listings.my_inactive_listings_info')"
          :project-slug="project?.slug ?? ''"
          @updated:items="onUpdatedItems(tabNames.inactive, $event)"
        />
        <CommonTab
          :status="MyListingSearchStatusEnum.Sold"
          :name="tabNames.sold"
          :title="$t('my_listings.my_sold_listings')"
          :info="$t('my_listings.my_sold_listings_info')"
          :project-slug="project?.slug ?? ''"
          @updated:items="onUpdatedItems(tabNames.sold, $event)"
        />
        <ApprovalTab
          :name="tabNames.approval"
          @updated:items="onUpdatedItems(tabNames.approval, $event)"
        />
        <ConfirmationTab
          :name="tabNames.confirmation"
          @updated:items="onUpdatedItems(tabNames.confirmation, $event)"
        />
        <CommonTab
          :status="MyListingSearchStatusEnum.Draft"
          :name="tabNames.draft"
          :title="$t('my_listings.my_draft_listings')"
          :info="$t('my_listings.my_draft_listings_info')"
          :project-slug="project?.slug ?? ''"
          @updated:items="onUpdatedItems(tabNames.draft, $event)"
        />
      </hb-tabs-body>
    </hb-tabs>
    <empty-placeholder v-else-if="!userStore.user?.profile_set">
      <template #illustration>
        <img src="/images/my-listing-register-placeholder.svg" alt="" />
      </template>
      <template #title>{{ $t("my_listings.missing_profile_title") }}</template>
      <template #text>
        {{ $t("my_listings.missing_profile_description") }}
      </template>
      <template #cta>
        <hb-btn size="lg" to="/register">
          <span>{{ $t("register.start_registration") }}</span>
        </hb-btn>
      </template>
    </empty-placeholder>
    <empty-placeholder v-else>
      <template #illustration>
        <img src="/images/my-listing-placeholder.svg" alt="" />
      </template>
      <template #title>{{ $t("my_listings.empty_title") }}</template>
      <template #text>{{ $t("my_listings.empty_body") }}</template>
      <template #cta>
        <start-selling-btn size="lg" />
      </template>
    </empty-placeholder>

    <template #bottom>
      <client-only v-if="isTablet">
        <div class="bottom-actions">
          <hb-btn-wrap>
            <hb-btn
              theme="light"
              size="md"
              outline
              @click="
                navigateToAgent(userStore.user.name, userStore.user.identifier)
              "
            >
              <nuxt-icon name="house-list" filled />
              <span>{{ $t("my_listings.my_personal_listing_space") }}</span>
            </hb-btn>
            <start-selling-btn theme="light" size="md" outline />
          </hb-btn-wrap>
        </div>
      </client-only>
    </template>
  </inner>
</template>

<script lang="ts">
import type { MyListingPropertyListItem } from "@homebourse/api-client";
import { MyListingSearchStatusEnum } from "@homebourse/api-client";
import { useAsyncData } from "#app";
import { useHead } from "#head";
import { computed, ref } from "vue";
import SubHeader from "~/components/layout/SubHeader.vue";
import Inner from "~/layouts/inner.vue";
import HbBtn from "~/components/base/utils/HbBtn.vue";
import {
  definePageMeta,
  useUserPropertyStore,
  useUserStore,
  useProjectStore,
  useViewTools,
  onMounted,
} from "#imports";
import HbBtnWrap from "~/components/base/utils/HbBtnWrap.vue";
import HbTabs from "~/components/base/tab/HbTabs.vue";
import HbTabsBody from "~/components/base/tab/HbTabsBody.vue";
import HbTabNavs from "~/components/base/tab/HbTabNavs.vue";
import CommonTab from "~/modules/profile/listings/components/tab/CommonTab.vue";
import ApprovalTab from "~/modules/profile/listings/components/tab/ApprovalTab.vue";
import ConfirmationTab from "~/modules/profile/listings/components/tab/ConfirmationTab.vue";
import StartSellingBtn from "~/modules/home/components/StartSellingBtn.vue";
import {
  ListingTabs,
  useListings,
} from "~/modules/profile/listings/composables/listing";
import HbTabNav from "~/components/base/tab/HbTabNav.vue";
import EmptyPlaceholder from "~/components/EmptyPlaceholder.vue";
import BlockLoader from "~/components/loaders/BlockLoader.vue";
import { navigateToAgent } from "~~/src/modules/home/utils/property-tools";
import { useRoute } from "#app";

export default {
  name: "MyListingsPage",
  components: {
    BlockLoader,
    EmptyPlaceholder,
    HbTabNav,
    StartSellingBtn,
    SubHeader,
    Inner,
    HbBtn,
    HbBtnWrap,
    HbTabs,
    HbTabsBody,
    HbTabNavs,
    CommonTab,
    ApprovalTab,
    ConfirmationTab,
  },
  setup() {
    definePageMeta({
      layout: false,
      mustAuth: true,
    });

    useHead({
      title: "My listings",
    });

    const project: any = ref<any>({});
    const loaded = ref(false);
    const activeTabIndex = ref(0);
    const listing = useListings();
    const userStore = useUserStore();
    const projectStore = useProjectStore();
    const userPropertyStore = useUserPropertyStore();
    const route = useRoute();
    const {
      data: counts,
      pending,
      execute: fetchCounts,
    } = useAsyncData(`listing-counts-${userStore.user?.id ?? 0}`, () =>
      userPropertyStore.api
        .myListingCount({
          project_id: (route?.query?.project as any) ?? null,
        })
        .then((res) => {
          if (userStore.user) {
            userStore.user.active_my_listing_count = res?.active;
          }
          return Promise.resolve(res);
        })
    );
    const noResults = computed(
      () => Object.values(counts.value || {}).reduce((a, b) => a + b, 0) === 0
    );
    const initialActiveTabName = computed<string>(() => {
      let name: string = listing.initialTab.value as string;

      if (!counts.value?.[name as string]) {
        const index = Object.values(counts.value).findIndex((v) => v > 0);
        if (index > -1) {
          name = Object.keys(counts.value)[index];
        }
      }

      return name;
    });
    const onUpdatedItems = (
      tabName: ListingTabs,
      items: MyListingPropertyListItem[]
    ) => {
      counts.value[tabName] = items?.length || 0;

      if (tabName === ListingTabs.active && userStore.user) {
        userStore.user.active_my_listing_count = items?.length || 0;
      }
    };

    const loadProject = async () => {
      try {
        const data = await projectStore.getProjectById(
          (route?.query?.project as any)
            ? parseInt(route?.query?.project as any)
            : null
        );
        // console.log(data);

        if (data) project.value = data.project;
        loaded.value = true;
      } catch (error) {}
    };

    onMounted(() => {
      (async () => {
        await loadProject();
      })();
    });

    return {
      MyListingSearchStatusEnum,
      counts,
      pending,
      userStore,
      noResults,
      activeTabIndex,
      initialActiveTabName,
      fetchCounts,
      navigateToAgent,
      onUpdatedItems,
      project,
      loaded,
      ...useViewTools(),
      ...listing,
    };
  },
};
</script>

<style lang="scss">
.listing-custom {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--hb-blue1);

    @include mobile {
      justify-content: start;
    }
  }

  &__label-style:active {
    color: var(--hb-blue2);
  }

  &__label-style a,
  &__label-style a:link,
  &__label-style a:visited,
  &__label-style a:hover,
  &__label-style a:active {
    text-decoration: none;
  }

  &__header-container {
    @include mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__cta-btn {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #e1e6e8;
    margin-right: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
  }

  &__cta-btn:hover {
    background-color: var(--hb-gray2);

    @include mobile {
      background-color: white;
    }
  }

  &__header-button {
    display: none;

    @include mobile {
      display: flex;
      width: 100%;
      max-width: 100%;
      margin-top: 26px;
      margin-bottom: 6px;
      justify-content: center;
      align-items: center;
    }
  }
}

.bottom-actions {
  padding: 20px 30px;
  gap: 20px;
  background-color: #fff;
}

.tab-content {
  &:first-of-type {
    .my-listings__row {
      margin-top: -30px;

      @include tablet {
        margin-top: 30px;
      }
    }
  }

  &:not(:first-of-type) {
    .my-listings__row {
      margin-top: 30px;
    }
  }
}

.my-listings {
  &__sort {
    display: flex;
    align-items: center;

    span {
      margin-right: 15px;
      font-size: 12px;
      color: #98a5ab;
    }

    @include mobile {
      width: 100%;

      span {
        display: none;
      }
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    transition: 0.3s all;
    margin-bottom: 60px;

    h2 {
      line-height: 1;
    }

    @include tablet {
      margin-top: 30px;
      gap: 20px;
    }
  }
  &__additional-row {
    margin-top: 30px;
  }
}

.button-wrapper {
  width: 100%;
}

.subheader--fixed .subheader__right {
  border: 0;
}
</style>
