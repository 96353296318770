<template>
  <inner>
    <template #subheader>
      <sub-header :fixed="true">
        <template #left>
          <div>
            <div class="subheader__title">{{ $t('link_friends.title') }}</div>
          </div>
        </template>
        <template #right>
          <start-selling-btn
            :size="isMobile ? 'sm' : 'md'"
            outline
            theme="light"
          />
        </template>
      </sub-header>
    </template>
    <stats :statistic="statistic">
      <template #select>
        <hb-select-native
          v-if="isTouchDevice()"
          v-model="selectedStatisticsPeriodOption"
          :options="periodOptions"
        />
        <hb-select-custom
          v-else
          v-model="selectedStatisticsPeriodOption"
          :options="periodOptions"
        />
      </template>
    </stats>
    <about />
    <div class="link__row">
      <div class="link__col">
        <div class="link__card">
          <div class="link__card-title">
            {{ $t('link_friends.deposit_withdrawal') }}
          </div>
          <p
            class="link__card-text"
            v-html="$t('link_friends.deposit_withdrawal_info')"
          />
        </div>
        <linked-friends :items="friendList" />
        <withdraw-history :items="withdrawList" />
      </div>
      <div class="link__col">
        <invite />
      </div>
    </div>
  </inner>
</template>

<script lang="ts">
import type {
  CommissionFriendList,
  CommissionFriendListItem,
  CommissionStatistics,
  CommissionWithdrawList,
  CommissionWithdrawListItem,
} from '@homebourse/api-client'
import { CommissionStatisticsPeriodEnum } from '@homebourse/api-client'
import { onMounted } from 'vue'
import Inner from '~/layouts/inner.vue'
import SubHeader from '~/components/layout/SubHeader.vue'
import StartSellingBtn from '~/modules/home/components/StartSellingBtn.vue'
import HbSelectNative from '~/components/base/form/HbSelectNative.vue'
import Stats from '~/modules/profile/link-friends/components/Stats.vue'
import About from '~/modules/profile/link-friends/components/About.vue'
import LinkedFriends from '~/modules/profile/link-friends/components/cards/LinkedFriends.vue'
import WithdrawHistory from '~/modules/profile/link-friends/components/cards/WithdrawHistory.vue'
import Invite from '~/modules/profile/link-friends/components/cards/Invite.vue'
import {
  computed,
  definePageMeta,
  ref,
  useAsyncData,
  useDeviceChecker,
  useFbq,
  useHead,
  useI18n,
  useUserStore,
  useViewTools,
  watch,
} from '#imports'
import { useCommissionStatisticsPeriodOptions } from '~/modules/profile/link-friends/composables/select-options'
import type { LabelValue } from '~/types'
import HbSelectCustom from '~/components/base/form/HbSelectCustom.vue'

export default {
  name: 'Link Friends',
  components: {
    HbSelectCustom,
    Inner,
    SubHeader,
    StartSellingBtn,
    HbSelectNative,
    Stats,
    About,
    LinkedFriends,
    WithdrawHistory,
    Invite,
  },

  setup() {
    const { t } = useI18n()

    useHead({
      title: t('profile.link_friends'),
    })

    definePageMeta({
      layout: false,
      mustAuth: true,
    })

    const userStore = useUserStore()
    const { track } = useFbq()

    const periodOptions = ref<LabelValue[]>(
      useCommissionStatisticsPeriodOptions()
    )
    const selectedStatisticsPeriodOption = ref(
      CommissionStatisticsPeriodEnum.Month
    )

    const { execute: getStatistic, data: statistic } =
      useAsyncData<CommissionStatistics>(() => {
        return userStore.api.commissionStatistics({
          period: selectedStatisticsPeriodOption.value,
        })
      })

    const { data: friendData } = useAsyncData<CommissionFriendList>(() => {
      return userStore.api.commissionFriends()
    })

    const { data: withdrawData } = useAsyncData<CommissionWithdrawList>(() => {
      return userStore.api.commissionWithdraws()
    })

    const friendList = computed(() => {
      return friendData.value?.data || ([] as CommissionFriendListItem[])
    })

    const withdrawList = computed(() => {
      return withdrawData.value?.data || ([] as CommissionWithdrawListItem[])
    })

    watch(
      () => selectedStatisticsPeriodOption.value,
      () => getStatistic()
    )

    onMounted(() => track('Lead', { content_name: 'Invite Friends' }))

    return {
      periodOptions,
      selectedStatisticsPeriodOption,
      statistic,
      friendList,
      withdrawList,
      ...useDeviceChecker(),
      ...useViewTools(),
    }
  },
}
</script>

<style lang="scss" src="~/assets/styles/profile/_link-friends.scss"></style>
