<template>
  <common>
    <div class="social">
      <div class="social__card">
        <div
          v-if="icon"
          class="social__icon"
          :class="{ 'social__icon--completed': !pending }"
        >
          <nuxt-icon :name="icon" filled class="social-logo" />
          <nuxt-icon name="close" filled class="connection" />
          <nuxt-icon name="logo" filled class="main-logo" />
        </div>
        <div class="social__card__content">
          <p v-if="pending">
            {{ $t('auth.connecting') }}
          </p>
          <p v-else>{{ $t('auth.connected') }}</p>
          <spinner v-if="pending" />
          <div v-else class="social__success-icon">
            <nuxt-icon name="check" filled />
          </div>
        </div>
      </div>
    </div>
  </common>
</template>

<script lang="ts">
import { showError, useRoute } from '#app'
import { computed } from 'vue'
import { definePageMeta, useApiHandler, useAuthStore } from '#imports'
import Common from '~/layouts/common.vue'
import Spinner from '~/components/loaders/Spinner.vue'
import type { SocialCallbackData } from '~/types'
import { SocialProviders } from '~/types'

const VALID_PROVIDERS = Object.values(SocialProviders)

export default {
  name: 'social-callback',
  components: { Spinner, Common },
  setup() {
    definePageMeta({
      layout: false,
      path: '/social/:provider/callback',
    })

    const route = useRoute()
    const { provider } = route.params ?? {}
    const queries = route.query ?? {}
    const icon = computed(
      () =>
        ({
          [SocialProviders.Facebook]: 'facebook-full',
          [SocialProviders.Google]: 'google-color',
          [SocialProviders.Apple]: 'apple',
        }[provider as string])
    )
    const authStore = useAuthStore()
    const { pending, execute } = useApiHandler(() =>
      authStore.api.socialLoginCallback({
        provider: provider as string,
        ...queries,
      })
    )

    if (!VALID_PROVIDERS.includes(provider as SocialProviders)) {
      showError({ statusCode: 404, message: 'Invalid social provider' })
    }

    execute(
      () => {
        setTimeout(() => {
          window.opener.postMessage({
            isSocialAuth: true,
            success: true,
          } as SocialCallbackData)
        }, 2000)
      },
      (error) => {
        window.opener.postMessage({
          isSocialAuth: true,
          success: false,
          message: error?.message,
        } as SocialCallbackData)
      }
    )

    return {
      provider,
      icon,
      pending,
    }
  },
}
</script>

<style lang="scss">
.social {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--hb-gray1);

  &__card {
    padding: 37px 15px 32px;
    border-radius: 16px;
    background: var(--hb-white);
    width: 95%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
      }
    }
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 30px;
    line-height: 1;

    .social-logo {
      transition: transform 1s;
      will-change: transform;
    }

    .connection {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 13px;
      transition: opacity 0.5s;
      transition-delay: 1.5s;
      opacity: 0;
    }

    .main-logo {
      position: absolute;
      transition: transform 1.5s, opacity 1.5s;
      will-change: transform;
      opacity: 0;
    }

    &--completed {
      .social-logo {
        transform: translateX(-42px);
      }

      .connection {
        opacity: 0.4;
      }

      .main-logo {
        opacity: 1;
        transform: translateX(42px);
      }
    }
  }

  &__success-icon {
    font-size: 24px;
    line-height: 1;
    color: var(--hb-green);
  }
}
</style>
