<template>
  <div style="display: contents">
    <div v-if="!!property?.id">
      <home-nav
        class="gallery-nav"
        :home-details="property"
        :slug="shareUrl"
        :conditional-back-btn="false"
        :mail-share-subject="$t('home.mail_share_subject')"
        :mail-share-body="mailShareBody"
        back-label="label.back"
      />
      <div class="gallery-page">
        <div class="container">
          <div class="gallery">
            <div
              v-for="(block, blockIndex) in blocks"
              :key="blockIndex"
              class="gallery__block"
              :class="{
                [`gallery__block--${block.type}`]: true,
              }"
            >
              <a
                v-for="(item, index) in block.items"
                :key="index"
                :ref="(element) => onGalleryItemLoad(element, item)"
                class="gallery__item"
                @click.prevent="onGalleryItemClick(item)"
              >
                <home-image
                  v-if="item.isImage"
                  class="gallery__image"
                  :image="item.image"
                  :show-largest="isMobile"
                />

                <gallery-video
                  v-if="item.isVideo"
                  :video="item.video"
                  :show-largest="isMobile"
                  :enabled="isMobile"
                />
                <gallery-tour
                  v-if="item.is3d"
                  :tour="item.tour"
                  :enabled="isMobile"
                  :preview-image="property?.images?.[0]"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <client-only>
        <gallery-lightbox
          v-if="showGalleryLightbox"
          v-bind="lightboxBinding"
          :items="galleryItems"
          :images="property?.images || []"
          v-on="lightboxListeners"
        />
      </client-only>
    </div>
    <block-loader v-else full-height no-radius />
  </div>
</template>

<script lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from '#app'
import { VideoStatusEnum } from '@homebourse/api-client'
import { canBeNumber } from 'wue'
import type { GalleryBlock, GalleryBlockItem } from '~/types'
import HomeNav from '~/modules/home/components/HomeNav.vue'
import HomeImage from '~/modules/home/components/HomeImage.vue'
import BlockLoader from '~/components/loaders/BlockLoader.vue'
import GalleryLightbox from '~/modules/gallery/components/GalleryLightbox.vue'
import { usePropertyStore } from '~/stores'
import {
  definePageMeta,
  useBaseUrl,
  useHomeShareEmailBody,
  useI18n,
  useSeoMeta,
  useViewTools,
} from '#imports'
import { useGalleryLightbox } from '~/modules/gallery/composables/useGalleryLightbox'
import { getGalleryBlocks } from '~/modules/gallery/utils/gallery-tools'
import GalleryVideo from '~/modules/home/components/GalleryVideo.vue'
import GalleryTour from '~/modules/home/components/GalleryTour.vue'

export default {
  name: '[slug]',
  components: {
    GalleryTour,
    GalleryVideo,
    GalleryLightbox,
    BlockLoader,
    HomeImage,
    HomeNav,
  },
  async setup() {
    onMounted(() => {
      setTimeout(() => {
        scrollElement.value?.scrollIntoView?.({
          behavior: 'smooth',
          block: 'center',
        })
      }, 0)
    })
    const { t } = useI18n()

    useSeoMeta({
      title: t('seo.gallery.title'),
      description: t('seo.gallery.description'),
    })

    const route = useRoute()
    const viewTools = useViewTools()
    const queryIndex = route.query?.index
    const scrollElement = ref<HTMLElement>(null)
    const scrolled = false
    const propertyStore = usePropertyStore()
    const homeSlug = computed<string>(() => route.params?.slug as string)
    const shareUrl = computed<string>(
      () => ('/home/' + route.params?.slug) as string
    )
    const fullUrl = useBaseUrl() + shareUrl.value
    const mailShareBody = useHomeShareEmailBody(fullUrl)
    const lightbox = useGalleryLightbox()
    const {
      data: property,
      pending,
      execute,
    } = await propertyStore.getPropertyBySlug(homeSlug.value)
    const galleryItems = computed<GalleryBlockItem[]>(() => [
      ...(property.value?.media_options?.tour?.url
        ? [
            {
              is3d: true,
              tour: property.value.media_options.tour,
            } as GalleryBlockItem,
          ]
        : []),
      ...(property.value?.videos || [])
        .filter((video) => video.status === VideoStatusEnum.Ready)
        .map((video) => ({
          isVideo: true,
          video,
        })),
      ...(property.value?.images || []).map((image) => ({
        isImage: true,
        image,
      })),
    ])
    const blocks = computed<GalleryBlock[]>(() => {
      return getGalleryBlocks(galleryItems.value)
    })
    const onGalleryItemLoad = (el: HTMLElement, item: GalleryBlockItem) => {
      if (item.collectionIndex === canBeNumber(queryIndex) && !scrolled) {
        scrollElement.value = el
      }
    }
    const onGalleryItemClick = (item: GalleryBlockItem) => {
      if (!viewTools.isMobile.value) {
        lightbox.openLightbox(item.index)
      }
    }

    definePageMeta({
      layout: 'gallery',
    })

    return {
      pending,
      property,
      blocks,
      galleryItems,
      homeSlug,
      shareUrl,
      mailShareBody,
      onGalleryItemLoad,
      onGalleryItemClick,
      ...lightbox,
      ...viewTools,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/home/home-shared';

.gallery-nav {
  position: sticky;
  top: 0;
  z-index: 3;
}

.gallery-page {
  padding: 80px 0 160px;

  @include tablet {
    padding: 0 0 30px;
  }
}

.gallery {
  margin: 0 auto;
  max-width: 950px;

  &__block {
    display: grid;
    grid-gap: 10px;
    margin-bottom: 10px;

    &--3 {
      grid-template-rows: auto auto;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: 'main top' 'main bottom';

      .gallery__item {
        display: block;
        position: relative;
        padding-top: 77%;

        &:nth-of-type(1) {
          grid-area: main;
        }

        &:nth-of-type(2) {
          grid-area: top;
        }

        &:nth-of-type(3) {
          grid-area: bottom;
        }
      }
    }

    &--1 {
      .gallery__item {
        position: relative;
        padding-top: 51.57%;
      }
    }

    &--2 {
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;

      .gallery__item {
        position: relative;
        padding-top: 100%;
      }
    }
  }

  &__item {
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    color: inherit;

    &:hover {
      .gallery__image {
        opacity: 0.7;
      }
    }
  }

  @include mobile {
    &__block {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .gallery__item {
        padding-top: 0;

        .gallery__image,
        .gallery-video {
          position: static;
          width: 100%;
          height: auto;
          display: block;
          object-fit: contain;
          object-position: unset;

          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }

    &__item {
      &:hover {
        .gallery__image {
          opacity: 1;
        }
      }
    }
  }
}
</style>
