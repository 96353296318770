<template>
  <component :is="btnTag" class="hb-btn" v-bind="btnBinding">
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { BtnMixin, useBtn } from 'wue'
import { computed } from 'vue'

export default {
  name: 'HbBtn',
  mixins: [BtnMixin],
  setup(props, ctx) {
    const computedProps = computed(() => ({
      ...props,
      themeClassPrefix: 'hb-btn',
      sizeClassPrefix: 'hb-btn',
      roundedClass: 'hb-btn--rounded',
      blockClass: 'hb-btn--block',
      outlineClass: 'hb-btn--outline',
      noShadowClass: 'hb-btn--no-shadow',
      outlineInverseClass: 'hb-btn--outline-inverse',
      loadingClass: 'hb-btn--loading',
      disabledClass: 'hb-btn--disabled',
    }))
    const btnCtx = useBtn(computedProps, ctx)

    return {
      ...btnCtx,
    }
  },
}
</script>

<style lang="scss">
.hb-btn {
  position: relative;
  display: inline-block;
  font-family: var(--hb-base-font);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.14px;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  padding: 12px 24px 12px 14px;
  cursor: pointer;
  background-color: var(--hb-btn-bg);
  border: 2px solid var(--hb-btn-bg);
  color: var(--hb-btn-color);
  box-shadow: 0 3px 20px var(--hb-btn-box-shadow);
  transition: 0.3s all;

  &--no-shadow {
    box-shadow: none !important;
  }

  span {
    vertical-align: middle;
  }

  .secondary-text {
    vertical-align: baseline;
    font-weight: 500;
  }

  .nuxt-icon {
    font-size: 27px;

    & + span {
      margin-left: 12px;
    }
  }

  span + .nuxt-icon {
    margin-left: 15px;
    font-size: 10px;
  }

  &-primary {
    @include btn-hover {
      --hb-btn-bg: var(--hb-red2);
    }

    &.hb-btn--disabled {
      background-color: var(--hb-gray4) !important;
      border-color: var(--hb-gray4) !important;
      box-shadow: none !important;
    }
  }

  &-secondary {
    --hb-btn-box-shadow: #2e138b57;
    --hb-btn-bg: var(--hb-secondary);

    .nuxt-icon {
      color: #fff;
    }

    @include btn-hover {
      --hb-btn-bg: var(--hb-blue3);
    }

    &.hb-btn--disabled {
      background-color: var(--hb-gray4) !important;
      border-color: var(--hb-gray4) !important;
      box-shadow: none !important;
    }
  }

  &-success {
    --hb-btn-box-shadow: rgba(#35a967, 0.3);
    --hb-btn-bg: var(--hb-green);
    font-size: 16px !important;

    .nuxt-icon {
      color: #fff;
      margin-right: 20px;
      font-size: 19px !important;
    }

    @include btn-hover {
      --hb-btn-bg: #31c872;
    }

    &.hb-btn--disabled {
      background-color: var(--hb-gray4) !important;
      border-color: var(--hb-gray4) !important;
      box-shadow: none !important;
    }
  }

  &-gray {
    background-color: #aab8be;
    border-color: #aab8be;
    color: #fff;
    box-shadow: none;

    @include btn-hover {
      background-color: darken(#aab8be, 5%);
    }

    &.hb-btn--disabled {
      background-color: var(--hb-gray4) !important;
      border-color: var(--hb-gray4) !important;
      box-shadow: none !important;
    }
  }

  &-confirm {
    font-size: 22px;
    padding: 11px 24px;
  }

  &-deny {
    font-size: 22px;
    padding: 11px 24px;
    background-color: #98a5ab;
    color: #fff;
    border-color: #98a5ab;
    width: 90px;
    text-align: center;
  }

  &-light {
    --hb-btn-box-shadow: transparent;
    --hb-btn-bg: var(--hb-white);
    --hb-btn-color: var(--hb-blue1);
    border: 1px solid var(--hb-gray1);

    &.hb-btn--outline {
      border: 2px solid var(--hb-gray2) !important;
      color: #aab8be;

      &:hover {
        color: #98a5ab;
        box-shadow: inset 0 0 0 3px var(--hb-gray2);
      }
    }
  }

  &-dark {
    --hb-btn-bg: var(--hb-blue1);
    --hb-btn-color: var(--hb-white);

    &.hb-btn--outline {
      border: 1px var(--hb-gray1) solid !important;
      box-shadow: none;

      .nuxt-icon {
        color: var(--hb-primary);
        transition: 0.3s all;
      }

      @include btn-hover {
        box-shadow: none;

        .nuxt-icon {
          color: var(--hb-secondary);
        }
      }

      &.hb-btn-sm {
        padding: 10px 20px;
        border-radius: 5px;
      }
    }
  }

  &-sm {
    font-size: 12px;
    padding: 0 20px 0;
    border-width: 1px !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 40px;
    width: fit-content;

    span {
      vertical-align: sub;
    }

    .nuxt-icon {
      font-size: 13px;
    }
  }

  &-sm-round {
    font-size: 14px;
    padding: 0 20px 0;
    border-width: 1px !important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: fit-content;

    span {
      vertical-align: sub;
    }

    .nuxt-icon {
      font-size: 21px;
    }
  }

  &-block-sm {
    width: 100%;
    font-size: 12px;
    padding: 0 20px 0;
    border-width: 1px !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    span {
      vertical-align: sub;
    }

    .nuxt-icon {
      font-size: 18px;
    }
  }

  &-md {
    font-size: 14px;
    padding: 12px 24px;
    height: 48px;

    .nuxt-icon {
      font-size: 20px;
    }
  }

  &-lg {
    font-size: 20px;
    padding: 14px 30px;
    line-height: 27px;

    .nuxt-icon {
      font-size: 27px;
    }

    @include mobile {
      font-size: 18px;
    }
  }

  &-block {
    display: block;
    width: 100%;
  }

  &-block-lg {
    display: block;
    width: 100%;
    font-size: 20px;
    padding: 14px 30px;
    line-height: 27px;

    .nuxt-icon {
      font-size: 27px;
    }

    @include mobile {
      font-size: 18px;
      padding: 14px 20px;
    }
  }

  &--rounded {
    border-radius: 100px;
  }

  &--outline {
    --hb-btn-color: var(--hb-blue1);
    background-color: transparent;
    border: 1px var(--hb-btn-bg) solid !important;
    box-shadow: inset 0 0 0 1px var(--hb-btn-bg);

    &:hover {
      box-shadow: inset 0 0 0 3px var(--hb-btn-bg);
    }
  }

  &--outline-inverse {
    --hb-btn-color: var(--hb-white);
    background-color: transparent;
    border: 2px var(--hb-white) solid !important;
    box-shadow: none;

    @include btn-hover {
      border-color: rgba(255, 255, 255, 0.5) !important;
      box-shadow: none;
    }
  }

  &--loading {
    @include btn-loading;
  }

  &--disabled {
    pointer-events: none;
    touch-action: none;
    user-select: none;
    user-focus: none;
    filter: grayscale(1);
    opacity: 0.7;
  }

  &-icon {
    background: none;
    color: var(--hb-blue1);
    padding: 0;
    border: 0;
    box-shadow: none;

    .nuxt-icon {
      font-size: 20px;
      transition: color 0.3s;

      @include tablet {
        font-size: 23px;
      }
    }

    @include btn-hover {
      .nuxt-icon {
        color: var(--hb-primary);
      }
    }
  }

  &-icon--bordered {
    border: 1px solid var(--hb-gray2);
    border-radius: 10px;
    padding: 16px;
  }

  &-icon--back {
    .nuxt-icon {
      font-size: 13px;
    }

    &.hb-btn-lg {
      padding: 13px 21px;
    }
  }

  &-icon--forward {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nuxt-icon {
      font-size: 10px;
    }
  }
}
</style>
