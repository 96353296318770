<template>
  <common>
    <block-loader :text="$t('invite.text')" full-height no-radius />
  </common>
</template>

<script lang="ts">
import { useHead } from '#head'
import { useRoute, useRouter } from '#app'
import BlockLoader from '~/components/loaders/BlockLoader.vue'
import Common from '~/layouts/common.vue'
import { definePageMeta, useI18n, useToasts } from '#imports'
import { Routes } from '~/enums'
import { useInviteCodeCookie } from '~/composables'

export default {
  name: 'InvitePage',
  components: { BlockLoader, Common },
  setup() {
    const { t } = useI18n()

    definePageMeta({
      layout: false,
    })

    useHead({
      title: t('label.invite'),
    })
    const router = useRouter()
    const route = useRoute()
    const { code } = route.params ?? {}
    const onWarning = async (message: string) => {
      await router.replace({ path: Routes.Main })
      useToasts().show({
        text: message,
        theme: 'warning',
      })
    }

    if (code) {
      useInviteCodeCookie().value = code as string
      router.push('/')
    } else {
      onWarning(t('label.invalid_url_parameters'))
    }
  },
}
</script>
