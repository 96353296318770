<template>
  <inner :dark="true">
    <template #subheader>
      <sub-header :fixed="true" :hide-right="true">
        <template #left>
          <div class="subheader__title">{{ $t('home.post_for_a_sale') }}</div>
        </template>
      </sub-header>
    </template>
    <div class="listing">
      <div class="listing__container">
        <div class="listing__row">
          <div class="listing__main">
            <hb-transition :overflowed="false">
              <upload-step1
                v-if="checkState(uploadSteps.step1)"
                v-model="model.propertyInit"
                :disabled="pending"
                :errors="errorBody?.errors"
              />

              <upload-step2
                v-if="checkState(uploadSteps.step2)"
                v-model="model.propertyDetails"
                :errors="errorBody?.errors"
              />

              <upload-step3
                v-if="checkState(uploadSteps.step3)"
                v-model="model"
                :errors="errorBody?.errors"
              />

              <upload-step4
                v-if="checkState(uploadSteps.step4)"
                v-model="model.propertySalesConditions"
                :errors="errorBody?.errors"
              />

              <upload-step5
                v-if="checkState(uploadSteps.step5)"
                v-model="model.propertyFeature"
                :errors="errorBody?.errors"
              />
            </hb-transition>

            <!-- Mobile nav btn only -->
            <div class="listing__card listing__card--nav">
              <div v-if="isLastStep" class="listing__sidebar-agreement">
                <p v-html="$t('home.seller_agreement_description')" />

                <hb-checkbox
                  v-model="model.accept_terms_of_use"
                  :label="$t('upload.accept_terms_conditions')"
                  name="accept_terms_of_use"
                />
                <hb-input-error-message
                  size="sm"
                  :errors="errorBody?.errors?.['accept_terms_of_use']"
                />
              </div>
              <hb-btn
                size="block-lg"
                theme="secondary"
                class="hb-btn-icon--forward"
                :loading="pending"
                @click="loadNextStep()"
              >
                <span v-if="isLastStep">{{
                  $t(isEdit ? 'label.publish' : 'home.send_to_confirm')
                }}</span>
                <span v-else>
                  {{
                    $t('label.next_to', [
                      $t(
                        [
                          'home.address_and_price',
                          'home.home_details',
                          'home.media',
                          'home.sales_conditions',
                          'home.facts_and_features',
                        ][currentState + 1]
                      ).toLowerCase(),
                    ])
                  }}
                </span>
                <nuxt-icon name="chevron-right"></nuxt-icon
              ></hb-btn>
              <hb-btn
                v-if="!prevDisabled"
                theme="light"
                outline
                size="block-lg"
                class="hb-btn-icon--back"
                :disabled="pending"
                @click="loadPrevStep()"
              >
                <nuxt-icon name="chevron-left"></nuxt-icon>
              </hb-btn>
            </div>
          </div>

          <div class="listing__sidebar">
            <div class="listing__sidebar-card">
              <div class="listing__sidebar-card-inner">
                <ul class="cip-indicator">
                  <li :class="getStepNavClasses(uploadSteps.step1)">
                    <span class="cip-indicator__item__dot" />
                    <a> {{ $t('home.address_and_price') }}</a>
                  </li>
                  <li :class="getStepNavClasses(uploadSteps.step2)">
                    <span class="cip-indicator__item__dot" />
                    <a>
                      {{ $t('home.home_details') }}
                    </a>
                  </li>
                  <li :class="getStepNavClasses(uploadSteps.step3)">
                    <span class="cip-indicator__item__dot" />
                    <a> {{ $t('home.media') }}</a>
                  </li>
                  <li :class="getStepNavClasses(uploadSteps.step4)">
                    <span class="cip-indicator__item__dot" />
                    <a> {{ $t('home.sales_conditions') }}</a>
                  </li>
                  <li :class="getStepNavClasses(uploadSteps.step5)">
                    <span class="cip-indicator__item__dot" />
                    <a> {{ $t('home.facts_and_features') }}</a>
                  </li>
                </ul>
                <div class="cip-indicator-mobile">
                  <span>0{{ currentState + 1 }}.</span>
                  {{
                    $t(
                      [
                        'home.address_and_price',
                        'home.home_details',
                        'home.media',
                        'home.sales_conditions',
                        'home.facts_and_features',
                      ][currentState]
                    )
                  }}
                </div>

                <div
                  v-if="isLastStep && !isTablet"
                  class="listing__sidebar-agreement"
                >
                  <p v-html="$t('home.seller_agreement_description')" />

                  <hb-checkbox
                    v-model="model.accept_terms_of_use"
                    :label="$t('upload.accept_terms_conditions')"
                    name="accept_terms_of_use"
                  />
                  <hb-input-error-message
                    size="sm"
                    :errors="errorBody?.errors?.['accept_terms_of_use']"
                  />
                </div>

                <div
                  class="listing__sidebar-btn-wrap"
                  :class="{ 'listing__sidebar-btn-wrap--final': isLastStep }"
                >
                  <hb-btn
                    v-if="!prevDisabled"
                    theme="light"
                    outline
                    size="lg"
                    class="hb-btn-icon--bordered hb-btn-icon--back"
                    :disabled="pending"
                    @click="loadPrevStep()"
                  >
                    <nuxt-icon name="chevron-left"></nuxt-icon>
                  </hb-btn>
                  <hb-btn
                    :theme="isLastStep ? 'success' : 'secondary'"
                    size="block-lg"
                    :loading="pending"
                    @click="loadNextStep()"
                  >
                    <nuxt-icon
                      v-if="isLastStep"
                      name="check"
                      filled
                    ></nuxt-icon>
                    <span style="margin-left: 0">
                      {{
                        $t(
                          isLastStep
                            ? isEdit
                              ? 'label.publish'
                              : 'home.send_to_confirm'
                            : 'label.next'
                        )
                      }}
                    </span>
                  </hb-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </inner>
</template>

<script lang="ts">
import { canBeNumber, documentScrollTo, useViewStates } from 'wue'
import { computed, onMounted, ref } from 'vue'
import { isClient } from '@vueuse/core'
import type { PropertyListing } from '@homebourse/api-client'
import { navigateTo, showError, useRoute } from '#app'
import Inner from '~/layouts/inner.vue'
import SubHeader from '~/components/layout/SubHeader.vue'
import {
  definePageMeta,
  useApiHandler,
  useDM,
  useFbq,
  useI18n,
  usePropertyStore,
  useSeoMeta,
  useViewTools,
} from '#imports'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import UploadStep1 from '~/modules/home/components/upload-page/UploadStep1.vue'
import UploadStep2 from '~/modules/home/components/upload-page/UploadStep2.vue'
import UploadStep3 from '~/modules/home/components/upload-page/UploadStep3.vue'
import UploadStep4 from '~/modules/home/components/upload-page/UploadStep4.vue'
import UploadStep5 from '~/modules/home/components/upload-page/UploadStep5.vue'
import HbTransition from '~/components/base/utils/HbTransition.vue'
import type { PropertyUploadData } from '~/modules/home/types'
import type { DeepPartial } from '~/types'
import FailureDialog from '~/components/dialogs/FailureDialog.vue'
import {
  getPropertyUploadData,
  getPropertyUploadRequestHandler,
  UploadSteps,
} from '~/modules/home/utils/upload-tools'
import UploadSuccessfulDialog from '~/components/dialogs/UploadSuccessfulDialog.vue'
import { AppEvents, useEmit } from '~/composables'
import HbCheckbox from '~/components/base/form/HbCheckbox.vue'
import HbInputErrorMessage from '~/components/base/form/HbInputErrorMessage.vue'

export default {
  name: 'upload',
  components: {
    HbInputErrorMessage,
    HbCheckbox,
    HbTransition,
    Inner,
    SubHeader,
    HbBtn,
    UploadStep1,
    UploadStep2,
    UploadStep3,
    UploadStep4,
    UploadStep5,
  },
  setup() {
    const { t } = useI18n()

    definePageMeta({
      layout: false,
      mustAuth: true,
      mustProfile: true,
      mustVerified: true,
      keepalive: true,
      key: 'Upload',
    })

    useSeoMeta({
      title: t('seo.sell.title'),
      description: t('seo.sell.description'),
    })

    const viewState = useViewStates(UploadSteps.step1, () => {
      if (isClient) {
        documentScrollTo({ top: 0 })
      }
    })
    const uploadSteps = ref<typeof UploadSteps>(UploadSteps)
    const route = useRoute()
    const propertyId = route.params?.id ?? null
    const isEdit = computed(() => canBeNumber(propertyId) !== false)
    const isDraft = ref(false)
    const model = ref<DeepPartial<PropertyUploadData>>(
      getPropertyUploadData(isEdit.value ? Number(propertyId) : null)
    )
    const isLastStep = computed(
      () => viewState.currentState.value === UploadSteps.step5
    )
    const prevDisabled = computed(
      () => viewState.currentState.value === UploadSteps.step1
    )
    const nextDisabled = computed(() => isLastStep.value)
    const propertyStore = usePropertyStore()
    const { track } = useFbq()
    const { execute: getProperty } = useApiHandler<PropertyListing>(() =>
      propertyStore.api.propertyListingGetById({ id: model.value.id })
    )
    const { execute, pending, errorBody } = getPropertyUploadRequestHandler(
      model,
      viewState.currentState
    )
    const loadPrevStep = () => {
      viewState.currentState.value--
    }
    const loadNextStep = async () => {
      await execute(
        () => {
          if (!isLastStep.value) {
            viewState.currentState.value++
          } else {
            useDM().open(UploadSuccessfulDialog, {
              isEdit: isEdit.value,
              isDraft: isDraft.value,
              myHome: !!model.value?.propertyInit?.ownership?.my_home,
              afterClose(data?: unknown) {
                navigateTo({
                  path:
                    isEdit.value && !isDraft.value
                      ? '/profile/listings/active'
                      : '/profile/listings/approval',
                })
              },
            })
          }
        },
        (error) => {
          if (!error.hasFormError && error.message) {
            useDM().open(FailureDialog, { message: error.message })
          }

          if (error?.hasFormError) {
            setTimeout(() => {
              useEmit(AppEvents.HomeUploadFailed)
            }, 0)
          }
        }
      )
    }
    const getStepNavClasses = (step: UploadSteps) => {
      return {
        'cip-indicator__item': true,
        'cip-indicator__item--disabled': viewState.currentState.value > step,
        'cip-indicator__item--active': viewState.currentState.value === step,
      }
    }

    if (model.value.id) {
      getProperty(
        (data) => {
          model.value = getPropertyUploadData(model.value.id, data)
          isDraft.value = !!data?.draft
        },
        () => {
          showError({
            statusCode: 404,
            message: t('home.property_not_found'),
            fatal: false,
          })
        }
      )
    }

    onMounted(() => track('SubmitApplication'))

    return {
      uploadSteps,
      ...viewState,
      isLastStep,
      prevDisabled,
      nextDisabled,
      model,
      pending,
      errorBody,
      isEdit,
      loadPrevStep,
      loadNextStep,
      getStepNavClasses,
      ...useViewTools(),
    }
  },
}
</script>

<style lang="scss" src="~/assets/styles/home/_upload.scss"></style>
