<template>
  <inner>
    <template #subheader>
      <sub-header :fixed="true">
        <template #left>
          <div class="subheader__title">
            <div>{{ $t('my_deposit.title') }}</div>
            <span v-tooltip.bottom="$t('my_deposit.info')">
              <nuxt-icon name="info" filled></nuxt-icon>
            </span>
          </div>
        </template>
        <template #right>
          <div class="deposit-head-wrap">
            <div class="deposit-head">
              <label class="deposit-head__label">
                {{ $t('my_deposit.current_balance') }}
              </label>
              <div v-if="!pending" class="deposit-head__number">
                {{ currentBalance }}
              </div>
              <block-placeholder
                v-else
                width="110%"
                height="24px"
                radius="0"
                opacity="0.8"
              />
            </div>
            <div class="deposit-head">
              <label class="deposit-head__label">
                {{ $t('my_deposit.pending_deposit') }}
              </label>
              <div v-if="!pending" class="deposit-head__number">
                {{ takenDeposit }}
              </div>
              <block-placeholder
                v-else
                width="110%"
                height="24px"
                radius="0"
                opacity="0.8"
              />
            </div>
            <hb-btn-wrap>
              <hb-btn
                theme="primary"
                size="md"
                class="deposit-btn"
                @click="onPayDeposit"
              >
                <nuxt-icon name="wallet" filled></nuxt-icon>
                <span>
                  {{ $t('my_deposit.pay_deposit') }}
                </span>
              </hb-btn>
              <hb-btn
                theme="light"
                type="button"
                class="hb-btn-gray"
                size="md"
                @click="onWithdrawDeposit"
              >
                <span>{{ $t('my_deposit.withdraw_deposit') }}</span>
              </hb-btn>
            </hb-btn-wrap>
          </div>
        </template>
      </sub-header>
    </template>

    <block-loader v-if="pending" :min-height="420" />
    <template
      v-else-if="finishedDepositRecords.length || pendingDepositRecords.length"
    >
      <list-table
        v-if="pendingDepositRecords.length"
        :title="$t('my_deposit.pending_actions')"
        :pending="pending"
      >
        <template #head>
          <list-table-col-head>{{ $t('label.amount') }}</list-table-col-head>
          <list-table-col-head>{{ $t('label.type') }}</list-table-col-head>
          <list-table-col-head>{{ $t('label.date') }}</list-table-col-head>
          <list-table-col-head>{{ $t('label.status') }}</list-table-col-head>
        </template>

        <list-table-row
          v-for="(item, index) in pendingDepositRecords"
          :key="`row_${index}_pending`"
        >
          <list-table-col top-right strong>
            {{ item.amount?.formatted_price ?? '--' }}
          </list-table-col>
          <list-table-col top-left :class="classAdd(item.type)">
            <nuxt-icon
              :name="isWithdrawn(item.type, 'deposit')"
              filled
            ></nuxt-icon>
            {{ $t(`my_deposit.status_${item.type}`) }}
          </list-table-col>
          <list-table-col bottom-left>
            {{ useDateFormat(item.created_at?.date_time) }}
          </list-table-col>
          <list-table-col bottom-right strong>
            {{ $t(`my_deposit.status_${item.status}`) }}
          </list-table-col>
        </list-table-row>
      </list-table>

      <list-table :title="$t('my_deposit.completed')" :pending="pending">
        <template #head>
          <list-table-col-head>{{ $t('label.amount') }}</list-table-col-head>
          <list-table-col-head>{{ $t('label.type') }}</list-table-col-head>
          <list-table-col-head>{{ $t('label.date') }}</list-table-col-head>
          <list-table-col-head>{{
            $t('my_deposit.total')
          }}</list-table-col-head>
        </template>

        <template v-if="finishedDepositRecords.length">
          <list-table-row
            v-for="(item, index) in finishedDepositRecords"
            :key="`row_${index}`"
          >
            <list-table-col top-right strong>
              {{ item.amount?.formatted_price ?? '--' }}
            </list-table-col>

            <!-- Állapotok: --withdraw, --used. Withdraw esetén icon változás -->
            <list-table-col top-left :class="classAdd(item.type)">
              <nuxt-icon
                :name="isWithdrawn(item.type, 'deposit')"
                filled
              ></nuxt-icon>
              {{ $t(`my_deposit.status_${item.type}`) }}
            </list-table-col>
            <list-table-col bottom-left>
              {{ useDateFormat(item.finished_at?.date_time) }}
            </list-table-col>
            <list-table-col bottom-right strong>
              {{ item.total?.formatted_price ?? '--' }}
            </list-table-col>
          </list-table-row>
        </template>
        <list-table-no-results v-else />
      </list-table>
    </template>
    <empty-placeholder v-else>
      <template #illustration>
        <img src="/images/deposit-placeholder.svg" alt="" />
      </template>
      <template #title>{{ $t('my_deposit.empty_title') }}</template>
      <template #text>
        <p>{{ $t('my_deposit.empty_body') }}</p>
        <ol>
          <li v-html="$t('my_deposit.empty_body_option_1')" />
          <li v-html="$t('my_deposit.empty_body_option_2')" />
        </ol>
        <p>{{ $t('my_deposit.empty_body_2') }}</p>
      </template>
      <template #cta>
        <hb-btn size="lg" @click="onPayDeposit()">
          <nuxt-icon name="wallet" filled></nuxt-icon>
          <span>
            {{ $t('my_deposit.pay_deposit') }}
          </span>
        </hb-btn>
      </template>
    </empty-placeholder>
  </inner>
</template>

<script lang="ts">
import { computed } from 'vue'
import type {
  DepositTransactionList,
  DepositTransactionListItem,
  DepositTransactionListItemTypeEnum,
} from '@homebourse/api-client'
import { useHead } from '#head'
import SubHeader from '~/components/layout/SubHeader.vue'
import Inner from '~/layouts/inner.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import ListTable from '~/components/ListTable.vue'
import ListTableRow from '~/components/ListTableRow.vue'
import ListTableCol from '~/components/ListTableCol.vue'
import ListTableColHead from '~/components/ListTableColHead.vue'
import {
  definePageMeta,
  useAsyncData,
  useI18n,
  useUserStore,
  useViewTools,
} from '#imports'
import { useUserPropertyStore } from '~/stores'
import HbBtnWrap from '~/components/base/utils/HbBtnWrap.vue'
import { useDateFormat, useDM } from '~/composables'
import PayDepositProcessDialog from '~/modules/home/components/dialogs/PayDepositProcessDialog.vue'
import WithdrawDepositDialog from '~/modules/home/components/dialogs/WithdrawDepositDialog.vue'
import ListTableNoResults from '~/components/ListTableNoResults.vue'
import EmptyPlaceholder from '~/components/EmptyPlaceholder.vue'
import BlockLoader from '~/components/loaders/BlockLoader.vue'
import { useDepositUpdater } from '~/modules/profile/utils/deposit.utils'
import BlockPlaceholder from '~/components/loaders/BlockPlaceholder.vue'

export default {
  name: 'MyDepositPage',
  components: {
    BlockPlaceholder,
    BlockLoader,
    EmptyPlaceholder,
    ListTableNoResults,
    SubHeader,
    Inner,
    HbBtn,
    ListTable,
    ListTableRow,
    ListTableCol,
    ListTableColHead,
    HbBtnWrap,
  },
  setup() {
    const { t } = useI18n()

    definePageMeta({
      layout: false,
      mustAuth: true,
    })

    useHead({
      title: t('profile.my_deposit'),
    })

    const userStore = useUserStore()
    const userPropertyStore = useUserPropertyStore()

    const {
      execute,
      data: depositData,
      pending,
    } = useAsyncData<DepositTransactionList>(() => {
      return userPropertyStore.api.depositTransactionList()
    })

    const finishedDepositRecords = computed<DepositTransactionListItem[]>(
      () => {
        return depositData.value?.data ?? []
      }
    )

    const pendingDepositRecords = computed<DepositTransactionListItem[]>(() => {
      return depositData.value?.pending_data ?? []
    })

    const currentBalance = computed<string>(() => {
      return depositData.value?.summary.current_balance?.formatted_price || '--'
    })

    const takenDeposit = computed<string>(() => {
      return depositData.value?.summary.taken_deposit?.formatted_price || '--'
    })

    const onPayDeposit = () => {
      const { depositUpdate } = useDepositUpdater()

      useDM().open(PayDepositProcessDialog, {
        async onSuccess() {
          pending.value = true
          await depositUpdate()
          await execute()
          pending.value = false
        },
      })
    }

    const onWithdrawDeposit = () => {
      const { depositUpdate } = useDepositUpdater()

      useDM().open(WithdrawDepositDialog, {
        async onSuccess() {
          pending.value = true
          await depositUpdate()
          await execute()
          pending.value = false
        },
      })
    }

    const isWithdrawn = (
      value: DepositTransactionListItemTypeEnum,
      val: string
    ) => {
      if (value === 'withdrawn') {
        return 'withdraw'
      }
      return val
    }

    const classAdd = (value: DepositTransactionListItemTypeEnum) => {
      if (value === 'withdrawn') {
        return 'col-type col-type--withdraw'
      } else if (value === 'used') {
        return 'col-type col-type--used'
      }
      return 'col-type'
    }

    return {
      finishedDepositRecords,
      pendingDepositRecords,
      currentBalance,
      takenDeposit,
      pending,
      onPayDeposit,
      onWithdrawDeposit,
      useDateFormat,
      isWithdrawn,
      classAdd,
      ...useViewTools(),
    }
  },
}
</script>

<style lang="scss">
.deposit-head-wrap {
  display: flex;

  @include mobile {
    flex-wrap: wrap;

    .button-wrapper {
      margin-top: 15px;
      flex: 0 0 100%;
      flex-direction: row;

      .hb-btn {
        font-size: 12px;
        padding: 12px 0;
      }
    }
  }
}
.deposit-head {
  margin-right: 45px;

  &__label {
    font-size: 12px;
    color: #aab8be;
    margin-bottom: 5px;
  }

  &__number {
    font-size: 22px;
    font-weight: 700;

    @include mobile {
      font-size: 18px;
    }
  }

  @include mobile {
    margin-right: 30px;
  }
}

.col-type {
  .nuxt-icon {
    font-size: 16px;
    margin-right: 10px;
    color: var(--hb-primary);
  }

  &--withdraw {
    color: #aab8be;

    .nuxt-icon {
      color: #aab8be;
    }
  }

  &--used {
    .nuxt-icon {
      color: var(--hb-blue1);
      transform: rotate(90deg);
    }
  }

  @include mobile {
    font-weight: 600;

    .nuxt-icon {
      margin-right: 5px;
    }
  }
}

@include mobile {
  .deposit-btn {
    &__text {
      display: none;
    }
  }
}
</style>
