<template>
  <div class="hb-dialog__body">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HbDialogBody',
}
</script>

<style scoped lang="scss">
.hb-dialog__body {
  padding: 25px var(--hb-dialog-x-padding);

  @include mobile {
    padding: 15px var(--hb-dialog-x-padding) 25px;
  }
}
</style>
