<template>
  <span class="hb-close-icon"></span>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'HbCloseIcon',
  props: {
    size: { type: Number, default: () => 18 },
  },
  setup(props) {
    const iconSize = computed(() => props.size + 'px')

    return {
      iconSize,
    }
  },
}
</script>

<style scoped lang="scss">
.hb-close-icon {
  position: relative;
  display: inline-block;
  width: v-bind(iconSize);
  height: v-bind(iconSize);
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 110%;
    height: 2px;
    display: inline-block;
    background: currentColor;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
</style>
