<template>
  <div class="hb-dialog__header">
    <div>
      <div v-if="$slots['subTitle']" class="hb-dialog__sub-title">
        <slot name="subTitle"></slot>
      </div>
      <div class="hb-dialog__title">
        <slot></slot>
      </div>
    </div>
    <hb-dialog-close
      v-if="allowClose"
      :top-position="closeTopPosition"
      :right-position="closeRightPosition"
      @click="dialog.close(afterClose)"
    />
  </div>
</template>

<script lang="ts">
import HbDialogClose from '~/components/base/dialog/HbDialogClose.vue'
import { useDialogInjection } from '~/composables'

export default {
  name: 'HbDialogHeader',
  components: { HbDialogClose },
  props: {
    allowClose: { type: Boolean, default: () => true },
    afterClose: {
      type: Function,
      default: () => {},
    },
    closeTopPosition: {
      type: String,
      default: '40px',
    },
    closeRightPosition: {
      type: String,
      default: '40px',
    },
  },
  setup() {
    const dialog = useDialogInjection()

    return {
      dialog,
    }
  },
}
</script>

<style scoped lang="scss">
.hb-dialog {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px var(--hb-dialog-x-padding) 15px;
    position: relative;
  }

  &__sub-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1.08px;
  }

  &--sm,
  &--xs {
    .hb-dialog__title {
      font-size: 30px;
    }
  }

  @include mobile {
    &__header {
      padding: 30px 60px 15px var(--hb-dialog-x-padding) !important;
    }

    &__title {
      font-size: 26px;
    }
  }
}
</style>
