<template>
  <inner>
    <div class="blog">
      <div class="blog__row" v-if="blogs.length > 0">
        <div v-for="post in blogs" :key="post.id" class="blog__col">
          <div class="blog__card" @click.prevent="routeToPost(post)">
            <div class="image-container">
              <img
                v-if="post?.cover"
                :src="post?.cover"
                class="post-cover"
                :alt="post?.cover_alt ?? post?.title"
              />
            </div>
            <div class="blog__card-title">
              {{ post?.title }}
            </div>
            <div class="blog__card-preview-description">
              {{ post?.preview_description }}
            </div>
            <div class="blog__card-link-wrap"></div>
            <hb-btn :to="`/blog/${post?.slug}`" size="sm" theme="dark" outline>
              <span>{{ $t("label.learn_more") }}</span>
              <nuxt-icon name="arrow-right" filled />
            </hb-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- <featured-projects /> -->
  </inner>
</template>

<script lang="ts">
import {
  definePageMeta,
  onMounted,
  ref,
  useBlogStore,
  useI18n,
  useSeoMeta,
} from "#imports";
import Inner from "~/layouts/inner.vue";
import HbBtn from "~/components/base/utils/HbBtn.vue";
import { useLiveChatWidget } from "~/composables";
import HbImage from "~/components/base/HbImage.vue";
import FeaturedProjects from "~~/src/modules/project/components/FeaturedProjects.vue";

export default {
  name: "BlogPage",
  components: {
    Inner,
    HbBtn,
    HbImage,
    FeaturedProjects,
  },

  methods: {
    routeToPost(post) {
      this.$router.push(`/blog/${post?.slug}`);
    },
  },

  setup(props, ctx) {
    const blogStore = useBlogStore();
    const blogs = ref([]);
    const { t } = useI18n();
    const { LiveChatWidget } = useLiveChatWidget();
    const openChat = () => {
      if (LiveChatWidget) {
        LiveChatWidget.call("maximize");
      }
    };

    useSeoMeta({
      title: t("seo.blog.title"),
      description: t("seo.blog.description"),
    });

    onMounted(async () => {
      const data = await blogStore.getBlogs();
      const isPublishedData = data.filter((item) => item.is_published);
      blogs.value = isPublishedData;
    });

    definePageMeta({
      layout: false,
    });

    return {
      blogs,
      openChat,
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/_blog.scss";
</style>
