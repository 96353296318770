<template>
  <common style="display: contents">
    <main-header />
    <div class="error">
      <div class="container">
        <div class="error__wrap">
          <div class="error__code">{{ $t('not_found.title') }}</div>
          <div class="error__message">{{ $t('not_found.message') }}</div>
          <div class="error__text" v-html="$t('not_found.text')" />
          <hb-btn to="/" theme="secondary" rounded size="lg">
            {{ $t('label.homepage') }}
          </hb-btn>
        </div>
      </div>
    </div>
  </common>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainHeader from '~/components/layout/MainHeader.vue'
import Common from '~/layouts/common.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import { definePageMeta } from '#imports'

export default defineComponent({
  name: '[...slug]',
  components: { HbBtn, Common, MainHeader },
  setup() {
    definePageMeta({
      layout: false,
    })

    return {}
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/error.scss';
</style>
