<template>
  <div :class="controlClasses">
    <div class="hb-form-control__wrap">
      <div v-if="hasPrefix" class="hb-form-control__suffix prefix">

        <slot></slot>
      </div>
      <input v-model="model" v-bind="inputData" />
      <div v-if="hasSuffix" class="hb-form-control__suffix">
        <slot></slot>
      </div>
    </div>
    <hb-input-error-message v-if="showError" :name="name" :errors="errors" />
  </div>
</template>

<script lang="ts">
import type { InputProps } from 'wue'
import { InputMixin, useInput } from 'wue'
import { computed } from 'vue'
import HbInputErrorMessage from '~/components/base/form/HbInputErrorMessage.vue'

export default {
  name: 'HbInput',
  components: { HbInputErrorMessage },
  mixins: [InputMixin],
  setup(props, ctx) {
    const inputCtx = useInput(
      computed(
        () =>
          ({
            ...props,
            controlClass: 'hb-form-control',
            filledClass: 'hb-form-control--filled',
            disabledClass: 'hb-form-control--disabled',
            invalidClass: 'hb-form-control--invalid',
            suffixClass: 'hb-form-control--suffix',
            suffixSelectClass: 'hb-form-control--suffix-select',
            noStyledSuffixClass: 'hb-form-control--suffix--no-style',
          } as InputProps)
      ),
      ctx
    )

    return {
      ...inputCtx,
    }
  },
}
</script>

<style lang="scss">
.hb-form-control__suffix.prefix {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    border: 2px solid var(--hb-gray2);
    border-right: 0;
    border-top-left-radius: 5px;
    box-shadow: inset -2px 0 0 -1px var(--hb-gray2);
    padding: 2px 10px;
}

.hb-form-control__suffix.prefix + input {
    border: 2px solid var(--hb-gray2);
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0;
}

.hb-form-control--suffix:focus-within input,
.hb-form-control--suffix:focus-within .hb-form-control__suffix.prefix {
    border-color: var(--hb-secondary);
}
.hb-form-control {
  input {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    padding: 11px 15px;
    outline: none !important;
    border: 2px solid var(--hb-gray2);
    border-radius: 5px;
    transition: border-color 0.2s linear;
    font-family: var(--hb-base-font);

    @include tablet {
      font-size: 16px !important;
    }

    &,
    &::placeholder {
      color: var(--hb-blue1);
      font-size: 12px;
      font-family: var(--hb-base-font);

      @include tablet {
        font-size: 16px !important;
      }
    }

    &::placeholder {
      font-weight: 500;
      padding: 0 !important;
    }

    &:active,
    &:focus {
      border-color: var(--hb-secondary);

      & + .hb-form-control__suffix {
        border-top-color: var(--hb-secondary);
        border-right-color: var(--hb-secondary);
        border-bottom-color: var(--hb-secondary);
      }
    }

    &[type='color'] {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      height: 50px;
      width: 50px;
      padding: 0;
      border-radius: 50%;
    }

    &[type='color']::-webkit-color-swatch {
      border-radius: 50%;
      padding: 0;
    }

    &[type='color']::-webkit-color-swatch-wrapper {
      border-radius: 10px;
      padding: 0;
    }
  }

  &--filled {
    input {
      border-color: var(--hb-gray4);

      & + .hb-form-control__suffix {
        border-top-color: var(--hb-gray4);
        border-right-color: var(--hb-gray4);
        border-bottom-color: var(--hb-gray4);
      }
    }
  }

  &--disabled {
    pointer-events: none;
    touch-action: none;
    user-select: none;
    user-focus: none;

    input {
      background-color: rgba(#e1e6e8, 0.5);
    }

    .hb-form-control__suffix {
      background-color: rgba(#e1e6e8, 0.5);
    }
  }

  &--invalid {
    input {
      border-color: var(--hb-red1);

      & + .hb-form-control__suffix {
        border-top-color: var(--hb-red1);
        border-right-color: var(--hb-red1);
        border-bottom-color: var(--hb-red1);
      }
    }
  }

  &--sm {
    input {
      &::placeholder {
        font-weight: 500;
      }
    }
  }

  &--lg {
    input {
      font-size: 16px;
      padding: 18px 20px;
      border-radius: 10px;
      border-width: 3px;

      &::placeholder {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .hb-form-control__suffix {
      border-width: 3px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &--md {
    input {
      font-size: 16px;
      padding: 16px 20px;
      border-radius: 5px;
      font-weight: 500;

      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        opacity: 0.6;
      }
    }
  }

  &--suffix {
    .hb-form-control__wrap {
      display: flex;
    }

    input {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--suffix-select {
    .hb-form-control__suffix {
      padding: 0;

      .hb-select-native {
        border: 0;
      }
    }
  }

  &__suffix {
    border-top: 2px solid var(--hb-gray2);
    border-right: 2px solid var(--hb-gray2);
    border-bottom: 2px solid var(--hb-gray2);
    border-left: 0;
    box-shadow: inset 2px 0px 0px -1px var(--hb-gray2);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: var(--hb-blue1);
    transition: 0.3s border-color;
  }

  &--suffix--no-style {
    .hb-form-control__suffix {
      color: inherit;
      box-shadow: none;
    }
  }
}
</style>
