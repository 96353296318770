<template>
  <inner>
    <template #subheader>
      <sub-header :fixed="true" :hide-right="true">
        <template #left>
          <div class="subheader__title">{{ $t('my_acquisitions.title') }}</div>
        </template>
        <!-- <template #right>
          <hb-btn-wrap>
            <start-selling-btn size="md" outline theme="light" />
            <hb-btn to="/profile/invite-friends" size="md">
              <nuxt-icon name="user-plus" filled></nuxt-icon>
              <span>{{ $t('my_acquisitions.link_friends') }}</span>
            </hb-btn>
          </hb-btn-wrap>
        </template> -->
      </sub-header>
    </template>
    <block-loader
      v-if="
        (pending || historyPending) &&
        !activeItems.length &&
        !boughtItems.length &&
        !historyItems.length
      "
      :min-height="420"
    />
    <hb-form
      v-else-if="
        activeItems.length || boughtItems.length || historyItems.length
      "
      :no-style="true"
    >
      <hb-tabs :initial-active-name="initialTabName" :overflowed="false">
        <hb-tab-navs :secondary="true" :fixed-width="true">
          <hb-tab-nav :items="itemCount">
            {{ $t('label.active') }}
          </hb-tab-nav>
          <hb-tab-nav :disabled="!historyItems.length">{{
            $t('profile.history')
          }}</hb-tab-nav>
        </hb-tab-navs>
        <hb-tabs-body>
          <hb-tab :name="tabNames.Active">
            <div class="card-list-filter">
              <div class="card-list-filter__label">
                {{ $t('my_acquisitions.my_offers') }}
                <span
                  v-tooltip="$t('my_acquisitions.my_active_acquisition_info')"
                >
                  <nuxt-icon name="info" filled></nuxt-icon>
                </span>
              </div>
              <div class="card-list-filter__select">
                <hb-select-custom
                  v-model="selectedTypes"
                  :placeholder="$t('home.all_types')"
                  multiple
                  :close-on-select="false"
                  :options="homeTypeSelectOptions"
                />
                <div class="card-list-filter__sort">
                  <span>{{ $t('label.sort') }}:</span>
                  <hb-select-native
                    v-if="isTouchDevice()"
                    v-model="selectedAcquisitionOrderOption"
                    :options="orderOptions"
                    @change="getItems()"
                  >
                  </hb-select-native>
                  <hb-select-custom
                    v-else
                    v-model="selectedAcquisitionOrderOption"
                    :options="orderOptions"
                    @change="getItems()"
                  />
                </div>
              </div>
            </div>

            <div class="content-wrap">
              <card-list :cols="4" :pending="pending">
                <card-list-item
                  v-for="item in activeItems"
                  :key="item.offer.id"
                  :cols="4"
                >
                  <home-card
                    :property="item.property"
                    :offer="item.offer"
                    @open-offer-list="openOfferList(item.property)"
                  ></home-card>
                </card-list-item>
              </card-list>
              <no-results
                v-if="!pending && !activeItems.length"
                :min-height="230"
              >
                <p v-html="$t('my_acquisitions.no_results')" />
                <hb-btn to="/search">
                  <span>{{ $t('home.explore_properties') }}</span>
                </hb-btn>
              </no-results>

              <template v-if="boughtItems.length">
                <div class="card-list-filter card-list-filter--content">
                  <div class="card-list-filter__label">
                    {{ $t('home.bought') }}
                    <span
                      v-tooltip="$t('my_acquisitions.bought_acquisition_info')"
                    >
                      <nuxt-icon name="info" filled></nuxt-icon>
                    </span>
                  </div>
                </div>
                <card-list :cols="4" :pending="pending">
                  <card-list-item
                    v-for="item in boughtItems"
                    :key="item.offer.id"
                    :cols="4"
                  >
                    <home-card
                      :property="item.property"
                      :offer="item.offer"
                      :cancellable="isWaitingForConfirmation(item.offer)"
                      @canceled="getItems()"
                      @open-offer-list="openOfferList(item.property)"
                    ></home-card>
                  </card-list-item>
                </card-list>
              </template>
            </div>
          </hb-tab>

          <hb-tab :name="tabNames.History">
            <list-table :pending="historyPending">
              <template #head>
                <list-table-col-head>
                  {{ $t('profile.address') }}
                </list-table-col-head>
                <list-table-col-head>
                  {{ $t('label.amount') }}
                </list-table-col-head>
                <list-table-col-head>
                  {{ $t('label.days') }}
                </list-table-col-head>
                <list-table-col-head>
                  {{ $t('label.type') }}
                </list-table-col-head>
              </template>

              <template v-if="historyItems.length">
                <list-table-row
                  v-for="(item, index) in historyItems"
                  :key="`history_row_${index}`"
                >
                  <list-table-col middle strong>
                    {{ item.property.address?.full_address }}
                  </list-table-col>
                  <list-table-col top-right>
                    {{ item.offer.details.amount.formatted_price }}
                  </list-table-col>
                  <list-table-col bottom-left>
                    {{ item.offer.details.payment_deadline }}
                    {{ $t('home.days') }}
                  </list-table-col>
                  <list-table-col top-left strong>
                    {{ $t(`acquisition.offer_type_${item.offer.type}`) }}
                  </list-table-col>
                </list-table-row>
              </template>
              <list-table-no-results v-else />
            </list-table>
            <pagination></pagination>
          </hb-tab>
        </hb-tabs-body>
      </hb-tabs>
    </hb-form>
    <empty-placeholder v-else>
      <template #illustration>
        <img src="/images/acquisition-placeholder.svg" alt="" />
      </template>
      <template #title>{{ $t('my_acquisitions.empty_title') }}</template>
      <template #text>{{ $t('my_acquisitions.empty_body') }}</template>
      <template #cta>
        <location-search is-small></location-search>
      </template>
    </empty-placeholder>
  </inner>
</template>

<script lang="ts">
import { computed, ref, watch } from 'vue'
import { useAsyncData } from '#app'
import type {
  Acquisition,
  AcquisitionList,
  AcquisitionListRequest,
  MyListingPropertyListItem,
  Offer,
} from '@homebourse/api-client'
import { AcquisitionListStatusesEnum } from '@homebourse/api-client'
import { useHead } from '#head'
import Inner from '~/layouts/inner.vue'
import SubHeader from '~/components/layout/SubHeader.vue'
import {
  definePageMeta,
  objectToRequestData,
  parseSortValue,
  useDeviceChecker,
  useDM,
  useI18n,
  useUserPropertyStore,
  useUserStore,
} from '#imports'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbBtnWrap from '~/components/base/utils/HbBtnWrap.vue'
import StartSellingBtn from '~/modules/home/components/StartSellingBtn.vue'
import HbTabs from '~/components/base/tab/HbTabs.vue'
import HbTabsBody from '~/components/base/tab/HbTabsBody.vue'
import HbTabNavs from '~/components/base/tab/HbTabNavs.vue'
import HbTab from '~/components/base/tab/HbTab.vue'
import HbForm from '~/components/base/form/HbForm.vue'
import HbSelectNative from '~/components/base/form/HbSelectNative.vue'
import HomeCard from '~/modules/home/components/HomeCard.vue'
import CardList from '~/components/CardList.vue'
import CardListItem from '~/components/CardListItem.vue'
import ListTable from '~/components/ListTable.vue'
import ListTableRow from '~/components/ListTableRow.vue'
import ListTableCol from '~/components/ListTableCol.vue'
import ListTableColHead from '~/components/ListTableColHead.vue'
import Pagination from '~/components/Pagination.vue'
import { useAcquisitionOrderOptions } from '~/modules/acquisition/composables/select-options'
import NoResults from '~/components/NoResults.vue'
import ListTableNoResults from '~/components/ListTableNoResults.vue'
import { getFilterHomeTypeOptions } from '~/modules/search/utils/filter-options'
import HbSelectCustom from '~/components/base/form/HbSelectCustom.vue'
import HbTabNav from '~/components/base/tab/HbTabNav.vue'
import OfferListDialog from '~/modules/home/components/dialogs/OfferListDialog.vue'
import EmptyPlaceholder from '~/components/EmptyPlaceholder.vue'
import LocationSearch from '~/components/form/LocationSearch.vue'
import BlockLoader from '~/components/loaders/BlockLoader.vue'

enum TabNames {
  Active = 'active',
  History = 'history',
}

export default {
  name: 'acquisition',
  components: {
    BlockLoader,
    LocationSearch,
    EmptyPlaceholder,
    HbTabNav,
    HbSelectCustom,
    ListTableNoResults,
    NoResults,
    ListTableColHead,
    Inner,
    SubHeader,
    HbBtn,
    HbBtnWrap,
    StartSellingBtn,
    HbTabs,
    HbTabsBody,
    HbTabNavs,
    HbTab,
    HbForm,
    HbSelectNative,
    HomeCard,
    CardList,
    CardListItem,
    ListTable,
    ListTableRow,
    ListTableCol,
    Pagination,
  },
  setup() {
    const { t } = useI18n()

    definePageMeta({
      layout: false,
      mustAuth: true,
    })

    useHead({
      title: t('profile.my_acquisitions'),
    })

    const sameAsResidential = ref(false)
    const tabNames = ref(TabNames)
    const userStore = useUserStore()
    const userPropertyStore = useUserPropertyStore()

    const homeTypeSelectOptions = ref(getFilterHomeTypeOptions())
    const { initialValue, orderOptions } = useAcquisitionOrderOptions()
    const selectedTypes = ref([])
    const selectedAcquisitionOrderOption = ref(initialValue)

    const {
      data,
      pending,
      execute: fetchItems,
    } = useAsyncData(async () => {
      const result = await Promise.all([
        userPropertyStore.api.acquisitionList({
          ...objectToRequestData({
            statuses: [AcquisitionListStatusesEnum.WaitingForResponse],
            types: selectedTypes.value?.length
              ? selectedTypes.value
              : undefined,
          } as AcquisitionListRequest),
          ...parseSortValue(selectedAcquisitionOrderOption.value),
        }),
        userPropertyStore.api.acquisitionList({
          ...objectToRequestData({
            statuses: [
              AcquisitionListStatusesEnum.Accepted,
              AcquisitionListStatusesEnum.WaitingForConfirmation,
            ],
            types: selectedTypes.value?.length
              ? selectedTypes.value
              : undefined,
          } as AcquisitionListRequest),
          ...parseSortValue(selectedAcquisitionOrderOption.value),
        }),
      ])
      const active: AcquisitionList = result[0]
      const bought: AcquisitionList = result[1]

      return {
        active,
        bought,
      }
    })

    const {
      data: historyData,
      pending: historyPending,
      execute: fetchHistory,
    } = useAsyncData(`acquisitions-${userStore.user.id}-history`, () =>
      userPropertyStore.api.acquisitionHistoryList()
    )

    const activeItems = computed<Acquisition[]>(() => {
      return data.value?.active?.data ?? []
    })
    const boughtItems = computed<Acquisition[]>(() => {
      return data.value?.bought?.data ?? []
    })
    const initialTabName = ref(TabNames.Active)

    const historyItems = computed<Acquisition[]>(() => {
      return historyData.value?.data ?? []
    })
    const itemCount = computed(() => activeItems.value?.length ?? 0)
    const getItems = async () => {
      await fetchItems()

      if (
        initialTabName.value === TabNames.Active &&
        !activeItems.value?.length &&
        !boughtItems.value?.length &&
        !selectedTypes.value.length
      ) {
        initialTabName.value = TabNames.History
      }
    }
    const isWaitingForConfirmation = (offer: Offer) =>
      offer?.status === AcquisitionListStatusesEnum.WaitingForConfirmation
    const openOfferList = (property: MyListingPropertyListItem) => {
      useDM().open(OfferListDialog, {
        propertyListItem: property,
        afterClose(hasChange?: unknown | boolean) {
          if (hasChange) {
            getItems()
            fetchHistory()
          }
        },
      })
    }

    watch(
      () => selectedTypes.value,
      () => getItems(),
      { deep: true }
    )

    return {
      sameAsResidential,
      itemCount,
      activeItems,
      boughtItems,
      pending,
      historyPending,
      homeTypeSelectOptions,
      orderOptions,
      selectedTypes,
      selectedAcquisitionOrderOption,
      historyItems,
      tabNames,
      initialTabName,
      isWaitingForConfirmation,
      fetchHistory,
      getItems,
      openOfferList,
      ...useDeviceChecker(),
    }
  },
}
</script>

<style lang="scss">
.content-wrap {
  padding-top: 30px;
}

.card-list-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -30px;
  margin-bottom: 30px;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 0;
  }

  &--content {
    margin-top: 45px;
    margin-bottom: 35px;

    @include mobile {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }

  &__label {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;

    @include tablet {
      margin-bottom: 25px;
      font-size: 20px;
    }
  }

  &__select {
    display: flex;
    align-items: center;
    gap: 35px;

    .hb-select-native,
    .hb-select {
      width: 260px;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 15px;

      .hb-select-native,
      .hb-select {
        width: 100%;
      }

      select {
        width: 100%;
      }
    }
  }

  &__sort {
    display: flex;
    align-items: center;

    span {
      margin-right: 15px;
      font-size: 12px;
      color: #98a5ab;
    }

    @include mobile {
      width: 100%;

      span {
        display: none;
      }
    }
  }
}
</style>
