<template>
  <inner>
    <div class="categories">
      <div class="categories__sidebar">
        <div class="categories__sidebar-inner">
          <div class="categories__sidebar-title">
            {{ $t('link_friends.categories').toUpperCase() }}
          </div>
          <nuxt-link
            v-for="category in categories"
            :key="category.id"
            :to="`/faq/${category.id}`"
            class="categories__sidebar-link"
            :class="{
              'categories__sidebar-link--active':
                helpCategory.id === category.id,
            }"
          >
            <nuxt-icon name="chevron-right" filled />
            {{ $t(category.key) }}
          </nuxt-link>
        </div>
      </div>
      <div class="categories__content">
        <nuxt-link to="/faq" class="categories__back">
          <nuxt-icon name="arrow-left" filled />
          {{ $t('label.back') }}
        </nuxt-link>
        <div class="categories__title">
          <help-category-icon :category="helpCategory.id" />
          {{ $t(helpCategory.key) }}
        </div>
        <nuxt-link
          v-for="(question, index) in helpCategory.questions"
          :key="index"
          :to="`/faq/${helpCategory.id}/${question.slug}`"
          class="categories__content-link"
        >
          <nuxt-icon name="chevron-right" filled />
          {{ $t(question.key) }}
        </nuxt-link>
      </div>
    </div>
  </inner>
</template>

<script lang="ts">
import { ref } from 'vue'
import { definePageMeta, useHelpTools, useI18n, useSeoMeta } from '#imports'
import Inner from '~/layouts/inner.vue'
import type { HelpCategory } from '~/types'
import HelpCategoryIcon from '~/components/HelpCategoryIcon.vue'

export default {
  name: 'categories',
  components: {
    HelpCategoryIcon,
    Inner,
  },
  setup(props, ctx) {
    const { helpStore, helpCategory } = useHelpTools()
    const categories = ref<HelpCategory[]>(helpStore.getCategories())
    const { t } = useI18n()

    useSeoMeta({
      title: `${t(helpCategory.value.key)} - ${t('seo.faq.title')}`,
      description: t('seo.faq.description'),
    })

    definePageMeta({
      layout: false,
    })

    return {
      categories,
      helpCategory,
    }
  },
}
</script>

<style lang="scss" src="~/assets/styles/help/_help.scss"></style>
