<template>
  <common>
    <hb-dialog-overlay>
      <hb-dialog size="sm">
        <hb-dialog-header :allow-close="false">
          {{ $t('label.set_new_password') }}
        </hb-dialog-header>
        <hb-dialog-body>
          <reset-password-form
            :email="email"
            :token="token"
            @success="onSuccess"
          />
          <div class="reset-password-bottom-text">
            <nuxt-link to="/">{{ $t('label.back_to_home') }}</nuxt-link>
          </div>
        </hb-dialog-body>
      </hb-dialog>
    </hb-dialog-overlay>
  </common>
</template>

<script lang="ts">
import { useHead } from '#head'
import { useRoute, useRouter } from '#app'
import Common from '~/layouts/common.vue'
import { Routes } from '~/enums'
import HbDialogOverlay from '~/components/base/dialog/HbDialogOverlay.vue'
import HbDialog from '~/components/base/dialog/HbDialog.vue'
import HbDialogHeader from '~/components/base/dialog/HbDialogHeader.vue'
import HbDialogBody from '~/components/base/dialog/HbDialogBody.vue'
import ResetPasswordForm from '~/modules/auth/components/forms/ResetPasswordForm.vue'
import { definePageMeta, useI18n } from '#imports'
import { useToasts } from '~/composables'

export default {
  name: 'reset-password',
  components: {
    ResetPasswordForm,
    HbDialogBody,
    HbDialogHeader,
    HbDialog,
    HbDialogOverlay,
    Common,
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const { token, email } = (route.query ?? {}) as Record<string, string>
    const onWarning = async (message: string) => {
      await router.replace({ path: Routes.Main })
      useToasts().show({
        text: message,
        theme: 'warning',
      })
    }
    const onSuccess = async () => {
      await router.replace({ path: Routes.Main })
      useToasts().show({
        text: t('user.your_password_updated'),
        theme: 'success',
      })
    }

    definePageMeta({
      layout: false,
    })

    useHead({
      title: t('label.set_new_password'),
    })

    if (!token || !email) {
      onWarning(t('label.invalid_url_parameters'))
    }

    return {
      email,
      token,
      onSuccess,
    }
  },
}
</script>

<style scoped lang="scss">
.reset-password-bottom-text {
  margin-top: 24px;
  text-align: center;
  font-size: 14px;

  a {
    color: var(--hb-gray4);
    text-decoration: none;
  }
}
</style>
