<template>
  <inner>
    <template #subheader>
      <sub-header class="saved-homes__subheader">
        <template #left>
          <div class="subheader__title">
            <div>{{ $t('saved_homes.title') }}</div>
            <span class="saved-homes__badge">{{ savedHomesCount }}</span>
          </div>
        </template>
        <template #right>
          <div class="min-width">
            <hb-select-custom
              v-model="typeSelected"
              :placeholder="$t('home.all_types')"
              multiple
              :close-on-select="false"
              :options="typeOptions"
            />
          </div>
          <div class="saved-homes__sort-by">
            <label>{{ $t('label.sort') }}:</label>
            <div class="min-width">
              <hb-select-native
                v-if="isTouchDevice()"
                v-model="orderSelected"
                :options="orderOptions"
              />
              <hb-select-custom
                v-else
                v-model="orderSelected"
                :options="orderOptions"
              />
            </div>
          </div>
        </template>
      </sub-header>
    </template>
    <div v-show="pending || homes.length > hiddenHomes.length">
      <div class="saved-home-list">
        <div class="saved-home-list__row">
          <template v-if="!pending">
            <div
              v-for="home in homes"
              v-show="isVisible(home)"
              :key="home.slug"
              class="saved-home-list__col"
            >
              <home-card
                :property="home"
                sold-is-gray
                undoable-dislike
                highlight-addition
                @saved="onHomeSaved(home)"
                @unsaved="onHomeUnsaved(home)"
              />
            </div>
          </template>
          <template v-else>
            <div v-for="index in 4" :key="index" class="saved-home-list__col">
              <block-placeholder height="422px" />
            </div>
          </template>
        </div>
      </div>
      <pagination v-model="page" :total="homesTotal" :limit="homesLimit" />
    </div>
    <empty-placeholder v-show="!(pending || homes.length > hiddenHomes.length)">
      <template #illustration>
        <img src="/images/saved_homes_placeholder.svg" alt="" />
      </template>
      <template #title>
        {{ $t('saved_homes.empty_title') }}
      </template>
      <template #text
        >{{ $t('saved_homes.empty_text_1') }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.43"
          height="18.127"
          viewBox="0 0 20.43 18.127"
        >
          <path
            id="Icon_awesome-heart"
            data-name="Icon awesome-heart"
            d="M16.638,3.35a4.922,4.922,0,0,0-6.716.489l-.709.731L8.5,3.84A4.921,4.921,0,0,0,1.788,3.35a5.168,5.168,0,0,0-.356,7.483L8.4,18.024a1.128,1.128,0,0,0,1.63,0l6.964-7.191a5.165,5.165,0,0,0-.353-7.483Z"
            transform="translate(1.002 -1.245)"
            fill="rgba(194,205,209,0.3)"
            stroke="#aab8be"
            stroke-linecap="round"
            stroke-width="2"
          />
        </svg>
        {{ $t('saved_homes.empty_text_2') }}</template
      >
      <template #cta>
        <location-search is-small></location-search>
      </template>
    </empty-placeholder>
  </inner>
</template>

<script lang="ts">
import type {
  PropertyListItem,
  SavedProperties,
  SavedPropertiesTypesEnum,
} from '@homebourse/api-client'
import { useHead } from '#head'
import { useAsyncData } from '#app'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import type { LabelValue } from '~/types'
import SubHeader from '~/components/layout/SubHeader.vue'
import { useUserStore } from '~/stores'
import {
  definePageMeta,
  objectToRequestData,
  useDeviceChecker,
  useI18n,
} from '#imports'
import HbSelectNative from '~/components/base/form/HbSelectNative.vue'
import { useSavedHomeOrderOptions } from '~/modules/home/composables/select-options'
import { parseSortValue } from '~/utils'
import { getSavedHomeTypeFilterOptions } from '~/modules/home/utils/property-tools'
import HbSelectCustom from '~/components/base/form/HbSelectCustom.vue'
import Inner from '~/layouts/inner.vue'
import Pagination from '~/components/Pagination.vue'
import EmptyPlaceholder from '~/components/EmptyPlaceholder.vue'
import LocationSearch from '~/components/form/LocationSearch.vue'
import { useSavedHomesCountState } from '~/composables'
import HomeCard from '~/modules/home/components/HomeCard.vue'
import BlockPlaceholder from '~/components/loaders/BlockPlaceholder.vue'

interface HiddenHome {
  home: PropertyListItem
  index: number
}

export default {
  name: 'SavedHomesPage',
  components: {
    BlockPlaceholder,
    HomeCard,
    Pagination,
    HbSelectCustom,
    HbSelectNative,
    SubHeader,
    Inner,
    EmptyPlaceholder,
    LocationSearch,
  },
  setup(props, ctx) {
    const { t } = useI18n()

    useHead({
      title: t('saved_homes.title'),
    })

    definePageMeta({
      layout: false,
      mustAuth: true,
    })

    const userStore = useUserStore()
    const page = ref(1)
    const typeOptions = ref<LabelValue[]>(getSavedHomeTypeFilterOptions())
    const { orderOptions, initialValue } = useSavedHomeOrderOptions()
    const orderSelected = ref<string>(initialValue)
    const typeSelected = ref<SavedPropertiesTypesEnum[]>([])
    const savedHomesCount = useSavedHomesCountState()
    const hiddenHomes = ref<PropertyListItem[]>([])

    const {
      execute: getSavedHomes,
      data: homesData,
      pending,
    } = useAsyncData<SavedProperties>(
      `${userStore.user?.id}-saved-homes`,
      async () => {
        const response = await userStore.api.savedProperties({
          page: page.value,
          ...objectToRequestData({
            types: typeSelected.value.length ? typeSelected.value : undefined,
          }),
          ...parseSortValue(orderSelected.value),
        })
        savedHomesCount.value = response?.properties?.total ?? 0
        return response
      }
    )

    const homes = computed<PropertyListItem[]>(() => {
      return homesData.value?.properties?.data ?? []
    })
    const homesTotal = computed(() => homesData.value?.properties?.total ?? 0)
    const homesLimit = computed(() => homesData.value?.properties?.limit ?? 25)
    const isVisible = (home: PropertyListItem) => {
      return !hiddenHomes.value.find((item) => item.id === home.id)
    }
    const onHomeSaved = (home: PropertyListItem) => {
      const hiddenIndex = hiddenHomes.value.findIndex(
        (item) => item.id === home.id
      )
      if (hiddenIndex > -1) {
        hiddenHomes.value.splice(hiddenIndex, 1)
        savedHomesCount.value++
      }
    }
    const onHomeUnsaved = (home: PropertyListItem) => {
      hiddenHomes.value.push(home)

      if (savedHomesCount.value) {
        savedHomesCount.value--
      }
    }

    watch(
      () => orderSelected.value,
      () => getSavedHomes()
    )

    watch(
      () => page.value,
      () => getSavedHomes()
    )

    watch(
      () => typeSelected.value,
      () => getSavedHomes(),
      { deep: true }
    )

    onBeforeUnmount(() => getSavedHomes())

    return {
      homes,
      homesTotal,
      homesLimit,
      page,
      pending,
      orderOptions,
      orderSelected,
      typeOptions,
      typeSelected,
      savedHomesCount,
      hiddenHomes,
      isVisible,
      onHomeSaved,
      onHomeUnsaved,
      ...useDeviceChecker(),
    }
  },
}
</script>
<style lang="scss">
.saved-homes {
  &__subheader {
    //transition: height 0.3s;

    .min-width {
      min-width: 200px;
      width: 100%;
    }
  }

  &__badge {
    border: 3px solid var(--hb-gray2);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  &__filters {
    display: flex;
  }

  &__sort-by {
    margin-left: 20px;
    display: flex;
    white-space: nowrap;
    align-items: center;

    label {
      margin-right: 10px;
      font-size: 12px;
      color: #aab8be;
    }
  }

  &__filter-icon {
    display: none;
  }

  &__loader,
  &__no-results {
    padding: 35px;
    min-height: 400px;
    margin: 60px 0;
  }

  .subheader__title {
    display: flex;
    align-items: center;
  }

  @include mobile {
    &__badge {
      width: 33px;
      height: 33px;
      font-size: 14px;
    }

    &__sort-by {
      margin-left: 0;
      margin-top: 15px;
      width: 100%;

      label {
        display: none;
      }
    }
  }
}

.saved-home-list {
  padding-top: 20px;

  @include mobile {
    overflow: hidden;
    padding-top: 40px;
    margin: 0 -30px;
  }

  &__row {
    --hb-saved-home-list-gap: 22px;

    display: flex;
    flex-wrap: wrap;
    gap: var(--hb-saved-home-list-gap);

    @include mobile {
      --hb-saved-home-list-gap: 14px;

      @include hide-scroll;
      overflow-x: auto;
      flex-wrap: nowrap;
      padding: 0 30px;
      scroll-snap-type: x mandatory;
    }
  }

  &__col {
    flex: 0 0 calc(25% - 17px);

    .home-card {
      max-width: 100%;

      &__link-container {
        height: auto;
      }
    }

    @include desktop {
      flex: 0 0 calc(100% / 3 - 15px);
    }

    @include tablet {
      flex: 0 0 calc(50% - 11px);
    }

    @include mobile {
      flex: 0 0 calc(100% - 50px);
      scroll-snap-align: center;
    }
  }
}

@include mobile {
  .saved-homes > .container {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
