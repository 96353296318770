<template>
  <div>
    <project-hero
      v-if="project && project.id"
      :project-id="project.id"
      :title="project.name"
      :subtitle="project.description"
      :address="project.address"
      :hero-image="project.hero_img"
      :profile-image="project.profile_img"
      :discount-value="project?.discount"
    ></project-hero>
    <project-features
      v-if="
        project &&
        project.id &&
        project?.features &&
        project?.features?.length > 0
      "
      :features="project?.features"
    ></project-features>
    <project-about
      v-if="project && project.about"
      :about="project.about"
    ></project-about>
    <project-residences-carousel
      v-if="project && project.residences && project.residences.length > 0"
      :residences="project.residences"
      :description="
        project.descriptions && project.descriptions.residences_description
      "
    ></project-residences-carousel>
    <project-gallery
      v-if="project && project.medias && project.medias.length > 0"
      :medias="project.medias"
      :description="
        project.descriptions && project.descriptions.gallery_description
      "
    ></project-gallery>
    <!-- <project-residences
      v-if="
        (project && project.properties && project.properties.length > 0) ||
        (project.buildings && project.buildings.length > 0)
      "
      :project-id="project.id"
      :properties="hasBuildingProperties ? [] : project.properties"
      :properties-by-buildings="hasBuildingProperties ? project.buildings : []"
    ></project-residences> -->
    <project-floor-plans
      v-if="project && project.buildings && floorPlans.length > 0"
      :floor-plans="floorPlans"
      :description="
        project.descriptions && project.descriptions.floor_plans_description
      "
    ></project-floor-plans>
    <project-amenities
      v-if="project && project.amenities && project.amenities.length > 0"
      :amenities="project.amenities"
      :description="
        project.descriptions && project.descriptions.amenities_description
      "
    ></project-amenities>
    <project-cta-block
      v-if="project && project.cta_block"
      :data="project.cta_block"
      :project-id="project.id"
    ></project-cta-block>
    <project-location
      v-if="project && project.longitude && project.latitude"
      :lat="+project.latitude"
      :lng="+project.longitude"
    ></project-location>
    <project-location-amenities
      v-if="
        project &&
        project.location_amenities &&
        project.location_amenities.length > 0
      "
      :amenities="project.location_amenities"
      :description="
        project.descriptions &&
        project.descriptions.location_amenities_description
      "
    ></project-location-amenities>
    <project-downloads
      v-if="project && project.documents && project.documents.length > 0"
      :documents="project.documents"
    ></project-downloads>
    <project-team
      v-if="project && project.team_members && project.team_members.length > 0"
      :team="project.team_members"
      :description="
        project.descriptions && project.descriptions.team_description
      "
    ></project-team>
    <project-developers
      v-if="project && project.developers && project.developers.length > 0"
      :developers="project.developers"
    ></project-developers>
    <!-- <div class="section">
      <featured-projects />
    </div> -->
    <project-footer
      v-if="project && project.id"
      :project-id="project.id"
    ></project-footer>
    <!-- <project-callback-button v-if="project" :project-id="project.id" /> -->
    <chat-bot v-if="project&& project.id" :project_id="project.id" />
  </div>
</template>

<script lang="ts">
import type { Property } from '@homebourse/api-client'
import { computed } from 'vue'
import type {
  Project,
  ProjectBuilding,
  ProjectFloorPlanExtended,
} from '~/modules/project/types'
import {
  definePageMeta,
  navigateTo,
  onMounted,
  ref,
  useProjectStore,
  useRoute,
  useRuntimeConfig,
} from '#imports'
import { useRouter } from '#app'
import { useDM } from '~/composables'
import { useUserStore } from '~/stores'
import ProjectHero from '~/modules/project/components/ProjectHero.vue'
import ProjectFeatures from '~/modules/project/components/ProjectFeatures.vue'
import ProjectFooter from '~/modules/project/components/ProjectFooter.vue'
import ProjectAbout from '~/modules/project/components/ProjectAbout.vue'
import ProjectGallery from '~/modules/project/components/ProjectGallery.vue'
import ProjectDevelopers from '~/modules/project/components/ProjectDevelopers.vue'
import ProjectTeam from '~/modules/project/components/ProjectTeam.vue'
import ProjectDownloads from '~/modules/project/components/ProjectDownloads.vue'
import ProjectFloorPlans from '~/modules/project/components/ProjectFloorPlans.vue'
import ProjectAmenities from '~/modules/project/components/ProjectAmenities.vue'
import ProjectLocation from '~/modules/project/components/ProjectLocation.vue'
import ProjectResidences from '~/modules/project/components/ProjectResidences.vue'
import ProjectLocationAmenities from '~/modules/project/components/ProjectLocationAmenities.vue'
import ProjectResidencesCarousel from '~/modules/project/components/ProjectResidencesCarousel.vue'
import ProjectCallbackButton from '~/modules/project/components/ProjectCallbackButton.vue'
import ProjectCtaBlock from '~/modules/project/components/ProjectCtaBlock.vue'
import { propertyToListItem } from '~/modules/home/utils/property-tools'
import ChatBot from '~/components/chatbot/ChatWindow.vue'
import AuthDialog from '~/modules/auth/components/dialogs/AuthDialog.vue'
import FeaturedProjects from '~~/src/modules/project/components/FeaturedProjects.vue'

export default {
  name: 'project-landing',
  components: {
    ProjectHero,
    ProjectFeatures,
    ProjectFooter,
    ProjectAbout,
    ProjectGallery,
    ProjectDevelopers,
    ProjectTeam,
    ProjectDownloads,
    ProjectFloorPlans,
    ProjectAmenities,
    ProjectLocation,
    ProjectResidences,
    ProjectLocationAmenities,
    ProjectResidencesCarousel,
    ProjectCallbackButton,
    ChatBot,
    ProjectCtaBlock,
    FeaturedProjects,
  },
  setup() {
    definePageMeta({
      layout: 'common',
    })

    const project = ref({} as Project)
    const projectStore = useProjectStore()
    const floorPlans = ref([] as ProjectFloorPlanExtended[])
    const hasBuildingProperties = ref(false)

    const showLoginOnLoad = ref(false)

    const router = useRouter()

    if (
      !router.options.history.state.back &&
      router.options.history.state.replaced
    ) {
      showLoginOnLoad.value = true
    }

    const userStore = useUserStore()
    const storedUser = computed(() => userStore.user)
    const isLoggedIn = computed(() => !!storedUser.value)

    const route = useRoute()
    const slug = (route.params.slug as string) ?? (route.meta.slug as string)

    onMounted(async () => {
      const data = await projectStore.getProjectBySlug(slug, true)
      if (data?.error) {
        const baseUrl = useRuntimeConfig().public.baseUrl
        navigateTo(baseUrl + '/not-found', { external: true })
        return
      }

      const projectData = data?.project

      if (projectData.buildings && projectData.buildings.length > 0) {
        projectData.buildings.forEach((building: ProjectBuilding) => {
          if (building.floor_plan) {
            floorPlans.value.push({
              ...building.floor_plan,
              building_name: building.name,
            })
          }

          let allSqft = 0

          if (building.properties && building.properties.length > 0) {
            building.properties.forEach((property: Property, index) => {
              building.properties[index] = propertyToListItem(property)
              allSqft += property.properties?.size ?? 0
            })
          }

          building.average_sqft = allSqft
            ? Math.round(allSqft / building.properties.length)
            : 0
        })
      }

      if (projectData.properties && projectData.properties.length > 0) {
        projectData.properties.forEach((property: Property, index) => {
          projectData.properties[index] = propertyToListItem(property)
        })
      }

      project.value = projectData
      hasBuildingProperties.value = data?.hasBuildingProperties ?? false

      if (showLoginOnLoad.value || !isLoggedIn.value) {
        useDM().open(AuthDialog, {
          allowClose: false,
          initSignUp: true,
          trackingPageTitle: projectData?.name,
          trackingPagePath: `/project/${projectData?.slug}`,
          isTransparent: true,
        })
      }
    })

    return {
      project,
      floorPlans,
      hasBuildingProperties,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/project/_main.scss';
@import 'vue3-carousel/dist/carousel.css';
</style>
