<template>
  <inner>
    <template #subheader>
      <sub-header :fixed="true" :hide-right="true">
        <template #left>
          <div class="subheader__title">{{ $t('profile.title') }}</div>
        </template>
        <!-- <template #right>
          <hb-btn-wrap>
            <start-selling-btn size="sm-round" outline theme="light" />
            <hb-btn to="/profile/invite-friends" size="sm-round">
              <nuxt-icon name="user-plus" filled />
              <span>{{ $t('profile.link_friends') }}</span>
            </hb-btn>
          </hb-btn-wrap>
        </template> -->
      </sub-header>
    </template>

    <div class="account">
      <hb-tabs>
        <hb-tab-navs :secondary="true" :fixed-width="true">
          <hb-tab-nav>
            {{ $t('profile.profile') }}
          </hb-tab-nav>
          <hb-tab-nav>
            {{ $t('profile.documents') }}
          </hb-tab-nav>
        </hb-tab-navs>
        <hb-tabs-body>
          <profile-tab :pending="pending" />
          <documents-tab />
        </hb-tabs-body>
      </hb-tabs>
    </div>
  </inner>
</template>

<script lang="ts">
import { useHead } from '#head'
import { useAsyncData } from '#app'
import type { UserProfile } from '@homebourse/api-client'
import Inner from '~/layouts/inner.vue'
import SubHeader from '~/components/layout/SubHeader.vue'
import { definePageMeta, ref, useDM, useI18n, useUserStore } from '#imports'
import { useViewTools } from '~/composables'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbBtnWrap from '~/components/base/utils/HbBtnWrap.vue'
import StartSellingBtn from '~/modules/home/components/StartSellingBtn.vue'
import HbTabs from '~/components/base/tab/HbTabs.vue'
import HbTabsBody from '~/components/base/tab/HbTabsBody.vue'
import HbTabNavs from '~/components/base/tab/HbTabNavs.vue'
import EditAvatarDialog from '~/modules/auth/components/dialogs/EditAvatarDialog.vue'
import ChangePasswordDialog from '~/modules/auth/components/dialogs/ChangePasswordDialog.vue'
import ProfileTab from '~/modules/profile/components/tabs/ProfileTab.vue'
import DocumentsTab from '~/modules/profile/components/tabs/DocumentsTab.vue'
import HbTabNav from '~/components/base/tab/HbTabNav.vue'

export default {
  name: 'account-settings',
  components: {
    HbTabNav,
    DocumentsTab,
    ProfileTab,
    Inner,
    SubHeader,
    HbBtn,
    HbBtnWrap,
    StartSellingBtn,
    HbTabs,
    HbTabsBody,
    HbTabNavs,
  },
  setup() {
    const { t } = useI18n()

    definePageMeta({
      layout: false,
      mustAuth: true,
    })

    useHead({
      title: t('profile.account_settings'),
    })

    const userStore = useUserStore()
    const { data: profile, pending } = useAsyncData<UserProfile>(
      () => userStore.getProfile(),
      { default: () => null }
    )
    const openEditAvatarDialog = () => {
      useDM().open(EditAvatarDialog)
    }
    const openChangePasswordDialog = () => {
      useDM().open(ChangePasswordDialog)
    }

    const sameAsResidential = ref(false)

    return {
      profile,
      sameAsResidential,
      pending,
      openEditAvatarDialog,
      openChangePasswordDialog,
      ...useViewTools(),
    }
  },
}
</script>

<style lang="scss" src="~/assets/styles/profile/_account-settings.scss"></style>
