<template>
  <div class="search-page">
    <search-map
      :osm-id="osmId"
      :center-address="searchValue"
      :bounds="bounds"
      :region-id="customRegionId"
      :postal-code="postalCode"
      @after-search="onAfterSearch"
    />
  </div>
</template>

<script lang="ts">
import { canBeNumber, equalObjects } from 'wue'
import type { PropertySearch } from '@homebourse/api-client'
import { onBeforeUnmount, onMounted, ref, watch, computed } from 'vue'
import { useRoute, useRouter } from '#app'
import { checkBounds } from '~/utils'
import SearchMap from '~/modules/search/components/SearchMap.vue'
// import { useDM } from '~/composables'
import { useUserStore } from '~/stores'
// import AuthDialog from '~/modules/auth/components/dialogs/AuthDialog.vue'

import {
  definePageMeta,
  useFbq,
  useI18n,
  useLocationSearchState,
  useSeoMeta,
} from '#imports'
import LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral

export default {
  name: '[...slug]',
  components: { SearchMap },
  setup() {
    const { t } = useI18n()
    const setPageTitle = (title?: string) => {
      useSeoMeta({
        title: title ? t('search.search_in_text', [title]) : t('seo.search.title'),
        description: t('seo.search.description'),
      })
    }
    const route = useRoute()
    const { query } = route
    const osmId = ref<number>(null)
    const postalCode = ref<number>(null)
    const searchValue = ref<string>(null)
    const bounds = ref<LatLngBoundsLiteral>(
      query.bounds ? JSON.parse(query.bounds as string) : null
    )
    const customRegionId = ref<number>(
      canBeNumber(query.regionId)
        ? (canBeNumber(query.regionId) as number)
        : null
    )
    const locationState = useLocationSearchState()
    const { track } = useFbq()
    const onAfterSearch = (result: PropertySearch) => {
      setPageTitle(result?.name)
    }

    const userStore = useUserStore()
    const storedUser = computed(() => userStore.user)
    const isLoggedIn = computed(() => !!storedUser.value)

    if (!checkBounds(bounds.value)) {
      useRouter().replace({
        query: null,
      })
      bounds.value = null
    }

    definePageMeta({
      layout: 'search',
      keepalive: true,
      key: 'Search',
    })

    setPageTitle()

    watch(
      () => route.params.slug,
      (value, oldValue) => {
        if (!equalObjects(value as string[], oldValue as string[])) {
          const [_osmId, _searchValue] = value as string[]

          if (_osmId?.includes('postal-code:')) {
            postalCode.value = Number(_osmId.split(':')[1])
          } else {
            osmId.value = Number(_osmId) || null
          }

          searchValue.value =
            _searchValue || (isNaN(Number(_osmId)) ? _osmId : null)
        }
      },
      { immediate: true, deep: true }
    )

    // watch(
    //   () => isLoggedIn?.value,
    //   (value) => {
    //     if (!value) {
    //       useDM().open(AuthDialog, {
    //         allowClose: false,
    //       })
    //     }
    //   }
    // )

    onMounted(() => {
      // if (!isLoggedIn.value) {
      //   useDM().open(AuthDialog, {
      //     allowClose: false,
      //   })
      // }
      track('Search')
    })
    onBeforeUnmount(() => (locationState.value = null))

    return {
      osmId,
      searchValue,
      bounds,
      customRegionId,
      postalCode,
      onAfterSearch,
    }
  },
}
</script>

<style scoped lang="scss">
.search-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
