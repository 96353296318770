<template>
  <a
    :class="rootClasses"
    :style="{
      top: topPosition,
      right: rightPosition,
    }"
  >
    <close-icon />
  </a>
</template>

<script lang="ts">
import { computed } from 'vue'
import CloseIcon from '~/components/base/HbCloseIcon.vue'

export default {
  name: 'HbDialogClose',
  components: { CloseIcon },
  props: {
    isBordered: { type: Boolean, default: () => false },
    size: { type: String as () => 'sm', default: () => null },
    topPosition: { type: String, default: '40px' },
    rightPosition: { type: String, default: '40px' },
  },
  setup(props) {
    const rootClasses = computed(() => ({
      'hb-dialog__close': true,
      'hb-dialog__close--bordered': props.isBordered,
      [`hb-dialog__close--${props.size}`]: !!props.size,
    }))

    return {
      rootClasses,
    }
  },
}
</script>

<style lang="scss">
.hb-dialog__close {
  cursor: pointer;
  color: var(--hb-gray4);
  transition: 0.3s all;
  line-height: 1;
  position: absolute;

  &:hover {
    color: var(--hb-blue1);
  }

  &--bordered {
    background: var(--hb-white);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 30px 60px #aab8be80;
  }

  &--sm {
    padding: 13px;
  }
}
</style>
