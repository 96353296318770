<template>
  <common>
    <block-loader
      :text="$t('ownership_invitation.text')"
      full-height
      no-radius
    />
  </common>
</template>

<script lang="ts">
import { useRoute, useRouter } from '#app'
import { useHead } from '#head'
import BlockLoader from '~/components/loaders/BlockLoader.vue'
import OwnershipInvitationFailedDialog from '~/modules/home/components/dialogs/OwnershipInvitationFailedDialog.vue'
import Common from '~/layouts/common.vue'
import { Routes } from '~/enums'
import { useAuthStore, usePropertyStore } from '~/stores'
import { useApiHandler, useDM, useToasts } from '~/composables'
import { definePageMeta, useI18n } from '#imports'
import AuthDialog from '~/modules/auth/components/dialogs/AuthDialog.vue'
import OwnershipInvitationSignInFailedDialog from '~/modules/home/components/dialogs/OwnershipInvitationSignInFailedDialog.vue'

export default {
  name: 'OwnershipInvitationPage',
  components: { BlockLoader, Common },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const authStore = useAuthStore()
    const propertyStore = usePropertyStore()
    const { hash } = route.query ?? {}
    const { t } = useI18n()
    const { execute } = useApiHandler(async () => {
      await authStore.api.csrfToken()
      return propertyStore.api.propertyOwnershipInvitationAccept({
        propertyOwnershipInvitationAccept: {
          hash: hash ? (hash as string) : '',
        },
      })
    })
    const onWarning = async (message: string) => {
      await router.replace({ path: Routes.Main })
      useToasts().show({
        text: message,
        theme: 'warning',
      })
    }
    const onSuccess = () => {
      router.replace({ path: '/profile/listings/confirmation' })
    }
    const openAuthDialog = () => {
      useDM().open(AuthDialog, {
        signInInfo: t('ownership_invitation.log_in_to_finish'),
        afterClose(success?: boolean) {
          if (success) {
            onSuccess()
          } else {
            useDM().open(OwnershipInvitationSignInFailedDialog, {
              afterClose() {
                openAuthDialog()
              },
            })
          }
        },
      })
    }
    const ownershipInvitation = async () => {
      await execute(
        (response) => {
          if (response?.authenticated) {
            onSuccess()
          } else {
            openAuthDialog()
          }
        },
        (error) => {
          useDM().open(OwnershipInvitationFailedDialog, {
            message: error.message || t('ownership_invitation.request_failed'),
          })
        }
      )
    }

    definePageMeta({
      layout: false,
    })

    useHead({
      title: t('ownership_invitation.text'),
    })

    if (!hash) {
      onWarning(t('ownership_invitation.hash_required'))
    } else {
      ownershipInvitation()
    }
  },
}
</script>
