<template>
  <common>
    <block-loader :text="$t('verification.text')" full-height no-radius />
  </common>
</template>

<script lang="ts">
import { canBeNumber } from 'wue'
import { useHead } from '#head'
import { useRoute, useRouter } from '#app'
import BlockLoader from '~/components/loaders/BlockLoader.vue'
import Common from '~/layouts/common.vue'
import { Routes } from '~/enums'
import SuccessDialog from '~/components/dialogs/SuccessDialog.vue'
import { useApiHandler, useDM, useToasts } from '~/composables'
import { useAuthStore, useUserStore } from '~/stores'
import { definePageMeta, useI18n } from '#imports'

export default {
  name: 'verification',
  components: { BlockLoader, Common },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const authStore = useAuthStore()
    const userStore = useUserStore()
    const { userId, hash } = route.query ?? {}
    const { t } = useI18n()
    const { execute } = useApiHandler(async () => {
      const response = await authStore.api.verifyEmail({
        verifyEmail: {
          user_id: Number(userId),
          hash: hash as string,
        },
      })
      await userStore.getMe()
      return response
    })
    const onWarning = async (message: string) => {
      await router.replace({ path: Routes.Main })
      useToasts().show({
        text: message,
        theme: 'warning',
      })
    }
    const verify = async () => {
      await execute(
        () => {
          useDM().open(SuccessDialog, {
            message: t('verification.success_text'),
            afterClose() {
              router.replace({ path: Routes.Main })
            },
          })
        },
        (error) => {
          onWarning(error.message)
        }
      )
    }

    definePageMeta({
      layout: false,
    })

    useHead({
      title: t('auth.email_verification'),
    })

    if (!canBeNumber(userId) || !hash) {
      onWarning(t('label.invalid_url_parameters'))
    } else {
      verify()
    }

    return {}
  },
}
</script>

<style scoped></style>
