<template>
  <inner>
    <template #subheader>
      <sub-header fixed>
        <template #left>
          <div class="subheader-title">
            {{ $t('my_listings.missing_profile_title') }}
          </div>
        </template>
      </sub-header>
    </template>

    <div class="page-form-container">
      <register-form is-card @saved="onSaved()">
        <template #actions="{ pending, save }">
          <hb-btn-wrap>
            <hb-btn
              to="/"
              size="block-lg"
              theme="light"
              type="button"
              :disabled="pending.value"
              outline
            >
              <span>{{ $t('label.cancel') }}</span>
            </hb-btn>
            <hb-btn
              size="block-lg"
              theme="secondary"
              :loading="pending.value"
              type="submit"
              @click="save()"
            >
              <span>{{ $t('user.register') }}</span>
            </hb-btn>
          </hb-btn-wrap>
        </template>
      </register-form>
    </div>
  </inner>
</template>

<script lang="ts">
import { useHead } from '#head'
import { useRoute, useRouter } from '#app'
import Inner from '~/layouts/inner.vue'
import { definePageMeta, useI18n } from '#imports'
import RegisterForm from '~/modules/auth/components/forms/RegisterForm.vue'
import HbBtn from '~/components/base/utils/HbBtn.vue'
import HbBtnWrap from '~/components/base/utils/HbBtnWrap.vue'
import SubHeader from '~/components/layout/SubHeader.vue'

export default {
  name: 'register',
  components: { SubHeader, HbBtnWrap, HbBtn, RegisterForm, Inner },
  setup(props) {
    const { query } = useRoute()
    const router = useRouter()
    const from = (query.from as string) || '/'
    const { t } = useI18n()
    const onSaved = () => {
      router.push({ path: from })
    }

    definePageMeta({
      layout: false,
      mustAuth: true,
    })

    useHead({
      title: t('my_listings.missing_profile_title'),
    })

    return {
      onSaved,
    }
  },
}
</script>

<style lang="scss">
.subheader-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.1px;
}
</style>
