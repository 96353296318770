<template>
  <span v-if="errors.length" :ref="setErrorElement" :class="rootClasses">
    {{ errors.at(0) }}
  </span>
</template>

<script lang="ts">
import { computed } from 'vue'
import { FormErrorMixin, useFormErrorComponent } from 'wue'

export default {
  name: 'HbInputErrorMessage',
  mixins: [FormErrorMixin],
  setup(props) {
    const rootClasses = computed(() => ({
      'hb-input-error-message': true,
      'hb-input-error-message--block': props.isBlock,
      [`hb-input-error-message--${props.size}`]: true,
    }))
    const errorCtx = useFormErrorComponent(computed(() => props))

    return {
      rootClasses,
      ...errorCtx.value,
    }
  },
}
</script>

<style scoped lang="scss">
.hb-input-error-message {
  display: inline-block;
  color: var(--hb-red1);
  font-weight: 600;
  font-size: 12px;
  padding-top: 6px;

  &--sm {
    font-size: 10px;
  }

  &--block {
    padding-left: 0;
  }
}
</style>
