<template>
  <form :class="formClasses" v-bind="formBinding" v-on="formListeners">
    <slot v-bind="slotData"></slot>
  </form>
</template>

<script lang="ts">
import { FormMixin, useFormComponent } from 'wue'
import { computed } from 'vue'

export default {
  name: 'HbForm',
  mixins: [FormMixin],
  props: {
    isHorizontal: { type: Boolean, default: false },
    isInline: { type: Boolean, default: false },
    noStyle: { type: Boolean, default: false },
    isCard: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const formCtx = useFormComponent(props, ctx)
    const formClasses = computed(() => ({
      'hb-form': !props.noStyle,
      'hb-form--horizontal': props.isHorizontal,
      'hb-form--inline': props.isInline,
      'hb-form--card': props.isCard,
    }))
    return {
      formClasses,
      ...formCtx,
    }
  },
}
</script>

<style scoped lang="scss">
.hb-form {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &--horizontal {
    flex-direction: row;
  }

  &--inline {
    display: inline-flex;

    @include mobile {
      width: 100%;
    }
  }

  &--card {
    padding: 25px 49px;
    border-radius: 16px;
    box-shadow: 0px 30px 60px rgb(170 184 190 / 50%);
    background: var(--hb-white);

    @include mobile {
      padding: 15px 25px 25px;
    }
  }
}
</style>
